<template>
    <vue-simple-suggest ref="suggest"
        v-model="selected_value"
        mode="select"
        display-attribute="name"
        value-attribute="id"
        :list="simpleSuggestionList"
        @select="onSelect"
        @blur="onBlur"
        @input="onInput"
        :styles="autoCompleteStyle"
        >
        <!-- Filter by input text to only show the matching results -->
        <input :placeholder="placeholder" type="search" v-model="input_value">

    </vue-simple-suggest>

</template>

<script>
import axios from 'axios';
    import VueSimpleSuggest from 'vue-simple-suggest'
    import 'vue-simple-suggest/dist/styles.css' // Optional CSS
    export default {
        components: {
            VueSimpleSuggest,
        },
        name: 'select-master-value',
        props: ['default_value', 'model_class_name', 'placeholder'],
        data() {
            return {
                selected_value: null,
                input_value: '',
                autoCompleteStyle : {
                    vueSimpleSuggest: "position-relative",
                    inputWrapper: "",
                    defaultInput : "form-control",
                    suggestions: "position-absolute list-group z-1000",
                    suggestItem: "list-group-item"
                },
            };
        },
       watch: {
          default_value: function(val, old) {   

            this.input_value = this.default_value;
          },


       },

        methods: {
            onInput() {
                if ((this.selected_value != null || this.default_value != null) && this.input_value == "") {
                    this.selected_value = null;
                    //  親に通知
                    this.$emit('selected', null);
                }


            },
            onSelect() {
                //  親に通知
                console.log("onSelect　master", this.selected_value, this.input_value);

                if (this.selected_value != null) {
                    this.$emit('selected', this.selected_value);
                }
            },
            onBlur() {
                if (this.$refs.suggest.textLength == 0 && this.selected_value != null) {
                    this.selected_value = null;
                    //  親に通知
                    this.$emit('selected', null);
                }
            },
            simpleSuggestionList() {
                return new Promise((resolve, reject) => {
                    if (this.$refs.suggest.textLength) {
                        let search_word = this.$refs.suggest.text;

                        let query = {
                            model_class_name: this.model_class_name,
                            search_word: search_word, 

                        };
                        let url = '/api/suggest';
                        axios.post(url, query).then(({ data }) => {
                            resolve(data.data.data);
                        });


                    } else {
                        resolve([]);
                    }
                });

            },


        },
        mounted() {
            //  初期表示
            this.input_value = this.default_value;

        },
    }
</script>
<style lang="scss">
.z-1000 {
  z-index: 1000;
}
.hover {
  background-color: #007bff;
  color: #fff;
}
</style>