<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <div class="card"><!-- 検索-->
              <div class="card-header">
                <h3 class="card-title">検索条件</h3>

                <div class="card-tools">
                  <button type="button" class="btn  btn-sm btn-primary" @click="resetSearchParams()">
                      クリア
                  </button>

                    <button type="button" class="btn  btn-sm btn-primary" @click="getRows()">
                        <i class="fa fa-search"></i>
                        検索
                    </button>
                </div>
              </div>
              <div class="card-body">

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">契約種別</label>
                        <select class="form-control" v-model="searchParams.type" name="type">
                            <option :value="''"></option>
                            <option :value="consts.CONTRACT.HD">光ダイレクト</option>
                            <option :value="consts.CONTRACT.FC">フリーコール</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">電話番号</label>
                        <input v-model="searchParams.tel" type="text" name="tel"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>
                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">契約コード</label>
                        <input v-model="searchParams.contract_code" type="text" name="tel"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">お客様名</label>
                        <input v-model="searchParams.customer_id" type="text" name="customer_id"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>

              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card 検索-->
          </div>


          <div class="col-9">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">{{title}}一覧<span v-if="rows && rows.total">({{rows.total + '件中の' + rows.from + '〜'  + rows.to + '件' }})</span></h3>

                <div class="card-tools">
                  <button  :disabled="!isAnyRowChecked" type="button" class="btn btn-sm btn-primary" @click="onDeleteRows">
                      <i class="fa fa-plus-square"></i>
                      削除
                  </button>
                    <!--  インポート -->
                  <import  @imported="onImported" model="App\Models\CustomerTel" :keys="['type', 'tel']" :fields="['type', 'customer_id', 'tel', 'contract_code', 'additional_channel_count', 'additional_tel_count', 'started_on', 'ended_on']"  />

                  <button type="button" class="btn btn-sm btn-primary" @click="onCreate()">
                      <i class="fa fa-plus-square"></i>
                      追加
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" v-model="is_all_row_checked" @click="onClickAllRow($event)"/> 
                      </th>
                      <th>ID</th>
                      <th>電話番号</th>
                      <th>契約コード</th>
                      <th>お客様名</th>
                      <th>契約種別</th>
                      <th>操作</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="row in rows.data" :key="row.id">
                      <td>
                        <input type="checkbox" v-bind:checked="isRowChecked(row.id)" @click.stop="onClickRow($event, row.id)" />
                      </td>

                      <td>{{row.id}}</td>
                      <td>{{row.tel}}</td>
                      <td>{{row.contract_code}}</td>
                      <td>{{row.customer.name}}</td>
                      <td>{{consts.contractName(row.type)}}</td>
                      <td>
                        <a href="#" @click="onUpdate(row)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        <!-- span>
                        <a href="#" @click="onDelete(row)">
                            <i class="fa fa-trash red"></i>
                        </a>
                        </span -->

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer clearfix">
                  <pagination :data="rows" :limit="5" @pagination-change-page="getRows"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">新規作成</h5>
                    <h5 class="modal-title" v-show="editmode">編集</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateRow() : createRow()">
                    <div class="modal-body">
                            
                            <div class="form-group" v-if="!editmode">
                                <label>お客様</label>

                                <suggest :default_value="form.customer_id" :model_class_name="'App\\Models\\Customer'" :placeholder="''" @selected="onSelected($event)" />

                                <has-error :form="form" field="customer_id"></has-error>
                            </div>
                            <div class="form-group" v-if="editmode">
                                <label>お客様</label>
                                <label>{{this.current_row.customer.name}}</label>

                            </div>

                            <div class="form-group" v-show="form.type">
                                <label>契約種別</label>
                                <div class="form-check" >
                                    <label class="form-check-label" v-show="form.type==consts.CONTRACT.HD" >
                                        <!-- input class="form-check-input" type="radio"  :value="consts.CONTRACT.HD" v-model="form.type" disabled="true" -->
                                    光ダイレクト&nbsp;</label>
                                    <label class="form-check-label" v-show="form.type==consts.CONTRACT.FC" >
                                        <!-- input class="form-check-input" type="radio"  :value="consts.CONTRACT.FC" v-model="form.type" disabled="true" -->
                                    フリーコール&nbsp;</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>電話番号</label>
                                <input v-model="form.tel" type="text" required
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('tel') }">
                                <has-error :form="form" field="tel"></has-error>
                            </div>
                            <div class="form-group" v-if="form.type==consts.CONTRACT.HD">
                                <label>契約コード</label>
                                <input v-model="form.contract_code" type="text" 
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('contract_code') }">
                                <has-error :form="form" field="contract_code"></has-error>
                            </div>
                            <div class="form-group">
                                <label>追加ch数</label>
                                <input v-model="form.additional_channel_count" type="number" required
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('additional_channel_count') }">
                                <has-error :form="form" field="additional_channel_count"></has-error>
                            </div>
                            <div class="form-group">
                                <label>追加番号数</label>
                                <input v-model="form.additional_tel_count" type="number" required
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('additional_tel_count') }">
                                <has-error :form="form" field="additional_tel_count"></has-error>
                            </div>
                            <div class="form-group">
                                <label>契約開始日</label>
                                <input v-model="form.started_on" type="date" 
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('started_on') }">
                                <has-error :form="form" field="started_on"></has-error>
                            </div>
                            <div class="form-group">
                                <label>契約終了日</label>
                                <input v-model="form.ended_on" type="date" 
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('ended_on') }">
                                <has-error :form="form" field="ended_on"></has-error>
                            </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        <button v-show="editmode" :disabled="!checkValidate(false)" type="submit" class="btn btn-success">更新</button>
                        <button v-show="!editmode" :disabled="!checkValidate(false)" type="submit" class="btn btn-primary">新規作成</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
const THIS_API = '/api/customer_tel';
import common from '../../common.js'
import consts from '../../consts.js'
import Suggest from '../libs/Suggest.vue';
import Import from '../libs/Import.vue';

    export default {
        components: {
            Suggest,
            Import,
        },
        data () {
            return {
                editmode: false,
                searchParams: {
                    type: '',             // 契約種別\n1:光ダイレクト、2:フリーコール
                    customer_id: '',      // お客様識別
                    tel: '',              // お客様電話番号
                    contract_code: '',    // 契約コード\nHDのときに有効。INFINITYの区分24（HD基本料金）の課金番号に対応する
                },

              rows : {},
              current_row: null,  //  編集時の行
              form: new Form({
                  id : '',
                  type: '',       // 契約種別\n1:光ダイレクト、2:フリーコール
                  customer_id: '',      // お客様識別
                  tel: '',              // お客様電話番号
                  contract_code: '',    // 契約コード\nHDのときに有効。INFINITYの区分24（HD基本料金）の課金番号に対応する
                  additional_channel_count: 0,     //  追加チャネル数\n追加ch基本料などの計算で単価にこの値をかけた料金が請求額となる
                  additional_tel_count: 0,         //  追加番号数\n追加番号基本料などの計算で単価にこの値をかけた料金が請求額となる
                  started_on: null,                   //  契約開始日
                  ended_on: null,                     //  契約終了日
              }),
                ////  チェック機能関係
                is_all_row_checked: false,
                checked_rows: [],   //  選択状態の行IDの配列（is_all_row_checkedがfalseのとき有効のとき有効）
                unchecked_rows: [],   //  非選択状態の行IDの配列（is_all_row_checkedがtrueのとき有効）
                ////

            }
        },
        computed: {
            title: function() {
                return 'お客様番号';
            },
            consts: function() {
                return consts;
            },
          isAnyRowChecked: function () {
            return (this.is_all_row_checked || this.checked_rows.length > 0);
          },


        },
        methods: {
            getRows(page = 1) {
                let params = ['page=' + page];

                for(let key in this.searchParams) {
                  params.push(key + '=' + encodeURIComponent(this.searchParams[key]));
                }
                let query = params.join('&');

                this.$Progress.start();
                
                axios.get(THIS_API + '?' + query).then(({ data }) => {
                    console.log('get rows', this.rows, data);

                    this.rows = data.data;
                });

                this.$Progress.finish();
            },
            resetSearchParams() {
                for(let key in this.searchParams) {
                    this.searchParams[key] = '';
                }
            },

            //  新規作成ボタンクリック時
            onCreate(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal({backdrop: "static",});
            },

            //  編集ボタンクリック時
            onUpdate(row){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal({backdrop: "static",});
                this.current_row = row;
                this.form.fill(row);
            },
            onDelete(row) {
                let title = row.tel;
                let id = row.id;

                Swal.fire({
                    title: title + 'を削除してよろしいですか？',
                    text: "この操作を取り消すことはできません",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: '削除',
                    cancelButtonText: 'キャンセル'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                axios.delete(THIS_API + '/'+id).then((response)=>{
                                    common.doResponse(response);
                                    this.getRows();
                                }).catch((data)=> {
                                  Swal.fire("失敗!", data.message, "warning");
                              });
                         }
                    })
            },
          //  選択された行を削除
          onDeleteRows() {

            Swal.fire({
              title: '選択された行を削除します。本当に削除してよろしいですか？',
//              input: 'checkbox',
//              inputValue: true,
//              inputPlaceholder: 'GenesysCluod上の通録データも削除する',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#3085d6',
              confirmButtonText: '削除',
              cancelButtonText: 'キャンセル',
              showLoaderOnConfirm: true,
              preConfirm: (login) => {
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                this.doDeleteRows();
                /*
                if (result.value) {
                  Swal.fire({icon: 'success', text: "削除しました"});
                } else {
                  Swal.fire({icon: 'error', text: "削除しました"});
                } 
                */               
              }
            });            
          },
          //  選択された行を削除
          doDeleteRows() {
            let result = {params:null};
            this.getParams(result);
            let params = result.params;

            this.$Progress.start();
            axios.post(THIS_API + '/deletes', params)
              .then((response) => {
                this.doResponse(response);
              }).catch((data)=> {
                Swal.fire("Failed!", data.message, "warning");
              });

          },
          doResponse(response, emitIfSuccess) {
                if(response.data.success){
                  // success
                  Swal.fire(
                    '削除完了!',
                    '削除されました',
                    'success'
                    );

                  //  再読み込み
                  this.getRows(this.selected_page);
                  this.$Progress.finish();
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: response.data.message
                  });
                  this.$Progress.finish();
              }
          },
          getParams(result) {
              result.params = {
                  is_all_row_checked: this.is_all_row_checked,
                  checked_rows: this.checked_rows,
                  unchecked_rows: this.unchecked_rows,
                  search_params: this.searchParams,
              };
          },

          
            createRow(){
              if (!this.checkValidate(true)) {
                  return;
              }
              this.$Progress.start();

              this.form.post(THIS_API)
              .then((data)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.getRows();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'エラー'
                  });
              })
            },
            updateRow() {
                if (!this.checkValidate(true)) {
                    return;
                }

                this.$Progress.start();
                this.form.put(THIS_API + '/'+this.form.id)
                .then((response) => {
                    // success
                    let result = common.doResponse(response);
                    this.$Progress.finish();

                    if (result) {
                        $('#addNew').modal('hide');
                        this.getRows();
                    }
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            checkValidate(is_show) {
              if (!this.form.tel) {
                if (is_show) common.toastError('お客様電話番号を入力してください');
                return false;
              }
              //  電話番号からスペースとハイフンを取り除く
              this.form.tel = common.toSearchTel(this.form.tel);

              if (!this.form.customer_id) {
                if (is_show) common.toastError('お客様を指定してください');
                return false;
              }
              if (!this.form.type) {
                if (is_show) common.toastError('契約種別を指定してください');
                return false;
              }
              return true;
            },

            //  サジェスト機能でお客様選択時
            onSelected(customer) {
                console.log('onselected', customer);
                if (customer) {
                    this.form.customer_id = customer.id;
                    this.form.type = customer.type;
                } else {
                    this.form.customer_id = '';
                    this.form.type = '';

                }
            },
          ////  チェック機能関係
          resetCheck() {
            this.checked_rows = [];
            this.unchecked_rows = [];
            this.is_all_row_checked = false;
          },
          onClickAllRow(event) {
            this.checked_rows = [];
            this.unchecked_rows = [];

          },
          onClickRow(event, key) {
//            console.log(event.target.checked);
            let isChecked = event.target.checked;
            let rows;
            if (this.is_all_row_checked) {
              //  すべてチェック状態のとき
              if (isChecked) {
                //  チェックされたら値を削除
                let index = this.unchecked_rows.indexOf(key);
                if (index != -1) {
                  this.unchecked_rows.splice(index, 1);
                }
              } else {
                //   チェック解除で値を追加
                this.unchecked_rows.push(key);
              }
            } else {
              //  すべてチェックされていない状態のとき
              if (isChecked) {
                //   チェックされたら値を追加
                this.checked_rows.push(key);
              } else {
                //  チェック解除で値を削除
                let index = this.checked_rows.indexOf(key);
                if (index != -1) {
                  this.checked_rows.splice(index, 1);
                }
              }
            }
          },
          isRowChecked(key) {
            if (this.is_all_row_checked) {
              //  すべてチェック状態のとき
              let index = this.unchecked_rows.indexOf(key);
              if (index != -1) {
                return false;
              } else {
                return true;
              }
            } else {
              //  すべてチェックされていない状態のとき

              let index = this.checked_rows.indexOf(key);
              if (index != -1) {
                return true;
              } else {
                return false;
              }

            }
          },
          /////

          //  インポート完了
          onImported() {
              this.getRows();
          },


        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.getRows();

            this.$Progress.finish();
        }
    }
</script>
