<template>
  <price-name :API="'/api/item_name'" :title="'品目コード'"  :model="'App\\Models\\ItemName'"/>
</template>

<script>
import PriceName from './PriceName.vue'

    export default {
        components: {
            PriceName,
        },

        mounted() {
        },
        created() {
        }
    }
</script>
