
<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">請求書作成</h3>

                <div class="card-tools">
                    <select name="output_type" v-model="output_type">
                        <option value="by_customer">お客様別フォルダに出力</option>
                        <option value="all">一括出力</option>
                    </select>
                  <button :disabled="!canCreate" type="button" class="btn btn-sm btn-primary" @click="onCreate">
                      <i class="fa fa-plus-square"></i>
                      作成
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                  <!--

                  <div v-if="!hd_priceNames" class="row m-t-1">
                    <div class="col-sm-12">
                        <div class="form-group">

                            <label>統合明細料金コード取得中。。。</label>
                        </div>
                    </div>
                  </div>
                  <div v-if="!fc_priceNames" class="row m-t-1">
                    <div class="col-sm-12">
                        <div class="form-group">

                            <label>基本工事料金コード取得中。。。</label>
                        </div>
                    </div>
                  </div>

                  -->
                  <div class="row m-t-1">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>請求月(年月のみ有効で日付はいつでも問題ありません。基本料金の日割り計算に必要となります。)</label>
                            <input type="date" v-model="seikyu_date" class="form-control">
                        </div>
                    </div>
                  </div>
                  
                  <div class="row m-t-1">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>光ダイレクト明細CSVファイル(セルフページ)</label>
                            <input type="file" multiple accept=".csv" v-on:change="hdFileSelected" class="form-control">
                        </div>
                    </div>
                  </div>
                  <div class="row m-t-1">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label>フリーコール明細CSVファイル（Infinity）</label>
                            <input type="file" accept=".csv" v-on:change="fcFileSelected" class="form-control">
                        </div>
                    </div>
                  </div>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

    </div>
  </section>
</template>

<script>
import common from '../common.js'
import consts from '../consts.js'
import JSZip from 'jszip'
import saveAs from 'file-saver';

    export default {
        computed: {
            canCreate: function() {
//                return this.hd_fileInfo && this.fc_fileInfo && this.hd_priceNames && this.fc_priceNames;
//                return this.hd_fileInfo && this.fc_fileInfo;
                return this.fc_fileInfo;
            },

        },
        data () {
            return {
//                tels : {},             //  お客様にわりあたっている電話番号
                fc_tels: {},             //  FC用の電話番号とカスタマーTEL(契約情報)の一覧
                hd_tels: {},             //  HD用の電話番号とカスタマーTEL(契約情報)の一覧
                contract_codes: {},      //  HD用の契約コードとカスタマーTEL(契約情報)の一覧
                customers: {},
//                hd_fileInfo: null,
                hd_fileInfos: null,
                fc_fileInfo: null,
//                hd_priceNames: {},      //  統合明細料金コードと名前の対応
//                fc_priceNames: {},      //  基本工事料金コードと名前の対応
                seikyu_date: null,
                output_type: 'by_customer',
                //  nfcのhdお客様IDとfcお客様IDの対応
                hd_to_fc_for_nfc: {
                    2: 1,
                    4: 11,
                    5: 12,
                    6: 13,
                    7: 14,
                    8: 15,
                    9: 16,
                    10: 17,
                },
                //  nfcのfcお客様IDと電話番号数の対応
                nfc_fc_id_to_count: null,
            }
        },
        methods: {
            //  明細CSVファイル選択時
            hdFileSelected(event){
//                this.hd_fileInfo = event.target.files[0]
                this.hd_fileInfos = event.target.files;

                //  TODO
//                this.createHdInvoice(null);
            },
            fcFileSelected(event){
                this.fc_fileInfo = event.target.files[0]
            },
            onCreate() {
                this.createInvoice();
            },
            createInvoice() {
                let fileInfo = this.fc_fileInfo;    //  infinityファイル

                common.readTextFile(fileInfo).then(text => {


                    let rows = common.makeArrayFromCSVText(text, true);     //  ヘッダーをのぞいてCSV配列を生成

//                    console.log('rows', rows);

                    //  レコード区分により分割
                    let [rows20, rows22, rows24, rows34] = this.divideRows(rows);

//                        console.log('customers rows20', rows20);
  //                      console.log('customers rows22', rows22);
    //                    console.log('customers rows24', rows24);
      //                  console.log('customers rows34', rows34);

                    //  FC基本料金の名前一覧を取得
                    this.getCodeNames(rows22, consts.FC_CSV_BASE_INDEX.CODE, '/api/fc_price_name_all')
                    
                        .then(result => {
                            console.log('FC codenames', result);
                            let fc_price_names = null;
                            if (result && result.data) {
                                fc_price_names = result.data.data;
                                
                                //  FCの請求書作成 (品目名に項目はFCにはない)
                                this.createFcInvoice(rows20, rows22, fc_price_names, null);
                            }

                        });

                    //  HD基本料金の名前一覧を取得
                    this.getCodeNames(rows24, consts.HD_CSV_BASE_INDEX.CODE, '/api/hd_price_name_all')
                        .then(result => {
                            console.log('HD codenames', result);
                            let hd_price_names = null;
                            if (result && result.data) {
                                hd_price_names = result.data.data;
                            }
                            return hd_price_names;
                        })
                        .then( (hd_price_names) => {
                            //  品目名一覧を取得
                            this.getCodeNames(rows24, consts.HD_CSV_BASE_INDEX.HINMOKU, '/api/item_name_all')
                                .then(result => {
                                    console.log('item codenames', result);
                                    let item_names = null;
                                    if (result && result.data) {
                                        item_names = result.data.data;
                                    }
                                    //  光ダイレクトの請求書を作成
                                    this.createHdInvoice(rows24, hd_price_names, item_names);
                                });
                        });


                });
            },
            //  FC請求書作成
            createFcInvoice(rows20, rows22, price_names, item_names) {
//                console.log('customers FC請求書作成', price_names);

                let zip = new JSZip();

                if (rows20.length > 0) {

                    //  FCの明細請求書を生成
                    zip = this.makeInvoiceZip(zip, consts.CONTRACT.FC, rows20)

                }
                if (rows22.length > 0 && price_names && zip) {

                    //  FCの基本工事料請求書を生成
                    zip = this.makeBaseInvoiceZip(zip, consts.CONTRACT.FC, rows22, price_names, item_names)

                }

                if (zip) {
                    //  zipをダウンロード
                    this.zipSaveAs(zip, "fc_invoice.zip");
                }



            },

            zipSaveAs(zip, file_name) {
                if (zip && zip.files) {
                    zip.generateAsync({type:"blob"})
                        .then(function(content) {
                            // see FileSaver.js
                            saveAs(content, file_name);
                        });
                }

            },
            divideRows(rows) {
                let rows20 = [];
                let rows22 = [];
                let rows24 = [];
                let rows34 = [];

                for (let i = 0; i < rows.length; i++) {
                    let row = rows[i];
                    let kubun = row[0];
                    if (kubun == 20) {
                        rows20.push(row);
                    } else if (kubun == 22) {
                        rows22.push(row);
                    } else if (kubun == 24) {
                        rows24.push(row);
                    } else if (kubun == 34) {
                        rows34.push(row);
                    }
                }
                return [rows20, rows22, rows24, rows34];

            },
            //  光ダイレクトの請求書作成
            createHdInvoice(rows24, price_names, item_names) {
//                console.log('customers HD請求書作成', price_names);

                let zip = new JSZip();
                console.log('hd fileinfos', this.hd_fileInfos);

                if (this.hd_fileInfos && this.hd_fileInfos.length > 0) {
                    //  すべてのCSVファイルを読み込む
                    for (let i = 0; i < this.hd_fileInfos.length; i++) {
                        let fileInfo = this.hd_fileInfos[i];
                        console.log('hd fileinfo', fileInfo);
                        common.readTextFile(fileInfo).then(text => {

                            //  フォーマットチェック
                            if (text.indexOf('ご利用電話番号,通話開始日時,通話開始時刻,着信電話番号,地域名,通話時間,内線区分,呼種別,通話料') === 0) {


                                let sub_rows = common.makeArrayFromCSVText(text, true);     //  ヘッダーをのぞいてCSV配列を生成

                                //  ファイルごとの行データを保存
                                this.hd_fileInfos[i]['rows'] = sub_rows;

//                                console.log('hd fileinfo rows index', i,  this.hd_fileInfos[i]['rows']);

                                //  すべてのファイル読み込みが完了したかチェック
                                if (this.isFinishHdFilesLoad()) {

                                    //  ファイルごとの行データを一つにまとめる
                                    let rows = this.mergeHdFilesRows();

//                                    console.log('hd fileinfo rows', rows);

                                    if (rows.length > 0) {
                                        //  HDの通話料金請求書を生成
                                        zip = this.makeInvoiceZip(zip, consts.CONTRACT.HD, rows)
                                    }

                                    if (zip) {

                                        //  基本料金を計算してzipダウンロード
                                        this.createHdInvoiceKihon(zip, rows24, price_names, item_names);
                                    }
                

                                }



                            } else {
                                alert(fileInfo.name + 'のCSV形式が光ダイレクト明細と異なります。');
                            }

                        });

                    }



                } else {
                    //  光ダイレクトの通話明細がないとき
                    //  基本料金のみ計算
                    this.createHdInvoiceKihon(zip, rows24, price_names, item_names);
                }

                /*
                let fileInfo = this.hd_fileInfo;

                if (fileInfo) {
                    common.readTextFile(fileInfo).then(text => {

                        //  フォーマットチェック
                        if (text.indexOf('ご利用電話番号,通話開始日時,通話開始時刻,着信電話番号,地域名,通話時間,内線区分,呼種別,通話料') === 0) {


                            let rows = common.makeArrayFromCSVText(text, true);     //  ヘッダーをのぞいてCSV配列を生成
                            console.log('hd csv rows', rows);


                            if (rows.length > 0) {
                                //  HDの通話料金請求書を生成
                                zip = this.makeInvoiceZip(zip, consts.CONTRACT.HD, rows)
                            }

                            //  基本料金を計算してzipダウンロード
                            this.createHdInvoiceKihon(zip, rows24, price_names, item_names);


                        } else {
                            alert('CSVの形式が光ダイレクト明細と異なります。');
                        }

                    });

                } else {
                    //  光ダイレクトの通話明細がないとき
                    //  基本料金のみ計算
                    this.createHdInvoiceKihon(zip, rows24, price_names, item_names);
                }
                */
            },
            //  すべてのファイルの読み込みが完了しているかチェック
            isFinishHdFilesLoad() {
                let isDone = true;
                for (let i = 0; i < this.hd_fileInfos.length; i++) {
                    if (!this.hd_fileInfos[i]['rows']) {
                        //  まだできていない
                        isDone = false;
                        break;
                    }
                }
                return isDone;
            },
            //  すべてのファイルの行をマージ
            mergeHdFilesRows() {
                let rows = [];
                for (let i = 0; i < this.hd_fileInfos.length; i++) {
                    let sub_rows = this.hd_fileInfos[i]['rows'];

                    rows = rows.concat(sub_rows);
                }
                return rows;
            },
          //  光ダイレクトの基本料金の請求書作成してzipをダウンロード
            createHdInvoiceKihon(zip, rows24, price_names, item_names) {
                if (rows24 && rows24.length > 0 && zip && price_names) {
//                    console.log('customers rows24', rows24);

                    //  HDの基本工事料請求書を生成
                    zip = this.makeBaseInvoiceZip(zip, consts.CONTRACT.HD, rows24, price_names, item_names)
                }

                if (zip) {
                    //  zipをダウンロード
                    this.zipSaveAs(zip, "hd_invoice.zip");

                }

            },
  

            getCodeNames(rows, index_code, url)  {
//                let index_code = (type == consts.CONTRACT.HD) ? consts.HD_CSV_BASE_INDEX.CODE : consts.FC_CSV_BASE_INDEX.CODE;
                let codes = [];
                for (let i = 0; i < rows.length; i++) {
                    let row = rows[i];
                    let code = row[index_code];
                    codes.push(code);
                }
//                let url = (type == consts.CONTRACT.HD) ? '/api/hd_price_name_all' : '/api/fc_price_name_all';

                if (codes.length > 0) {
                    return axios.post(url, { ids: codes } );
                } else {
                    return new Promise((resolve, reject) => {resolve(null)});
                }
            },

            //  NFCの選択番号通知利用料の特殊処理用にNFCのFCのお客様IDごとの番号数一覧を取得
            getFcTelCountForSentakubango()  {
                let url = '/api/customer_tel_record_count';
                //  nfcのfcお客様ID配列を取得
                let customer_ids = Object.values(this.hd_to_fc_for_nfc);

                return axios.post(url, { customer_ids: customer_ids } );
            },



            //  基本工事料の明細
            makeBaseInvoiceZip(zip, type, rows, price_names, item_names) {
//                console.log('customers 基本料金明細作成');
                let index_key, index_tel, index_price, index_code, index_hinmoku;
                let all_output_folder_name;
//                let price_names;
                if (type == consts.CONTRACT.HD) {
                    index_key = consts.HD_CSV_BASE_INDEX.KAKIN;
                    index_tel = consts.HD_CSV_BASE_INDEX.TEL;
                    index_price = consts.HD_CSV_BASE_INDEX.PRICE;
                    index_code = consts.HD_CSV_BASE_INDEX.CODE;
                    index_hinmoku = consts.HD_CSV_BASE_INDEX.HINMOKU;
                    all_output_folder_name = 'hd_invoice_all';
                } else {
                    index_key = consts.FC_CSV_BASE_INDEX.TEL;
                    index_tel = consts.FC_CSV_BASE_INDEX.TEL;
                    index_price = consts.FC_CSV_BASE_INDEX.PRICE;
                    index_code = consts.FC_CSV_BASE_INDEX.CODE;
                    index_hinmoku = -1;     // FCに品目コードはない
                    all_output_folder_name = 'fc_invoice_all';

                }
                let header = 'お客様ID,契約コード,電話番号,項目コード,項目名,元値,個数,単価,請求額,エラー\r\n';

                //  カスタマーごとに
                let key_to_customer_tels = type == consts.CONTRACT.HD ? this.contract_codes : this.fc_tels;     //  hdのときは電話番号ではなく契約コードがキーになる
                let customerRows = this.groupByCustomer(rows, index_key, key_to_customer_tels, type, 2);

                if (customerRows) {

                    let errors = [];

                    //  一括出力用
                    let base_rows = [];

                    //  顧客ごとに計算
                    for (let customer_id in customerRows) {
                        let customerRow = customerRows[customer_id];
                        let customer = this.findCustomer(customer_id);

                        //  明細ごとにCXからお客様へ請求する金額を計算
                        let base_unit_price = customer.base_unit_price.length > 0 ? customer.base_unit_price[0] : null;

                        if (base_unit_price == null || base_unit_price.units == null || base_unit_price.units.length == 0) {
                            //  エラー 
//                            errors.push('お客様[' + customer.name + ']に基本料金の請求単価が設定されていません');
                            //  単価設定が一つもされていない場合でも、必要項目のエラーを表示できるようにする。
                            base_unit_price = {
                                units: [],
                            };

                        }

                        let  zip_folder = null;
                        if (!this.is_output_all()) {
                            zip_folder = zip.folder(customer.name);
                        }

                        //  明細ごとに計算

                        let newRows = this.calcBaseInvoice(customer, base_unit_price.units, customerRow, index_key, index_tel, index_price, index_code, index_hinmoku, price_names, item_names, key_to_customer_tels);

                        if (newRows != null) {
                            if (!zip_folder) {
                                base_rows  = base_rows.concat(newRows);
                            } else {
                                let csvText = header + common.arrayToCSVText(newRows);
                                //  zipを作成
                                zip_folder.file("base.csv", csvText);
                            }
                        } else {
                            //  エラーが発生しているので終わる
                            zip = null;
                            break;
                        }

                    }
                    if (errors.length > 0) {
                        //  エラー表示して終わる
                        zip = null;
                        common.toastError( errors.join('\r\n'));
                    } else {
                        if (this.is_output_all()) {
                            //  一括出力用
                            if (base_rows.length > 0) {
                                let zip_folder = zip.folder(all_output_folder_name);

                                let csvText = header + common.arrayToCSVText(base_rows);
                                //  zipを作成
                                zip_folder.file("base.csv", csvText);
                            }
                        }

                    }
                } else {
                    zip = null;
                }
                return zip;

            },

            makeInvoiceZip(zip, type, rows) {
                //  光ダイレクト / フリーコールのときのCSVインデックスを取得
//                console.log('customers 通話明細作成', type, rows);
//                console.log('hd rows', rows);

                let header;
                let indexes;
                let new_meisai_indexes = [];
                let all_output_folder_name;
                if (type == consts.CONTRACT.HD) {
                    header = consts.HD_MEISAI_HEADER;
                    indexes = consts.HD_CSV_INDEX;
                    all_output_folder_name = 'hd_invoice_all';

                } else {
                    header = '';
                    header = consts.FC_MEISAI_HEADER;
                    indexes = consts.FC_CSV_INDEX;
                    all_output_folder_name = 'fc_invoice_all';
                }  
                for (let key in indexes) {
                    //  出力対象となるインデックスの一覧を生成
                    new_meisai_indexes.push(indexes[key]);
                }


                let key_to_customer_tels = type == consts.CONTRACT.HD ? this.hd_tels : this.fc_tels;
                let customerRows = this.groupByCustomer(rows, indexes.TEL, key_to_customer_tels, type, 1);

//                console.log('customerrows', type, customerRows);

                if (customerRows) {
                    let errors = [];
                    let price_error_customers = [];
                    let meisai_error_customers = [];

                    //  一括出力用の保存領域
                    let meisai_rows = [];
                    let error_meisai_rows = [];
                    let subtotal_rows = [];
                    let error_subtotal_rows = [];
                    
                    //  顧客ごとの通話時間の合計を計算
                    for (let customer_id in customerRows) {
                        let customerRow = customerRows[customer_id];
                        let customer = this.findCustomer(customer_id);

                        let  zip_folder = null;
                        if (!this.is_output_all()) {
                            zip_folder = zip.folder(customer.name);
                        }

                        //  明細ごとにCXからお客様へ請求する金額を計算
                        let cx_call_unit_price = customer.cx_call_unit_price.length > 0 ? customer.cx_call_unit_price[0] : null;

                        if (cx_call_unit_price == null) {
                            errors.push('[' + customer.name + ']に通話料金請求単価が設定されていません');

                        } else {
                            let [newRows, tel_to_call_type_to_subtotals, error_rows] = this.calcCxInvoice(type, customerRow, cx_call_unit_price, indexes.TEL, indexes.TIME, indexes.PRICE, indexes.CALL_TYPE, new_meisai_indexes, customer);

                            if (!zip_folder) {
                                meisai_rows = meisai_rows.concat(newRows);
                            } else {
                                //  通話料金明細のzipを作成
                                this.addZipFile(zip_folder, 'meisai.csv', header, newRows);
                            }

                            if (error_rows && error_rows.length > 0) {
                                //  KDDIからの請求見込み額が大きいとき
                                
                                if (!zip_folder) {
                                    error_meisai_rows = error_meisai_rows.concat(error_rows);
                                } else {
                                    //  エラーとなっている通話料金小計のzipを作成
                                    this.addZipFile(zip_folder, 'error_meisai_' + customer.name + ".csv", header, error_rows);
                                }

                                //  エラーを通知できるようにお客様名を保存
                                meisai_error_customers.push(customer.name);
                            }



                            //  KDDIからの請求額計算用
                            let kddi_call_unit_price = customer.kddi_call_unit_price.length > 0 ? customer.kddi_call_unit_price[0] : null;


                            let [subtotals, error_subtotals] = this.makeSubTotal(tel_to_call_type_to_subtotals, kddi_call_unit_price, customer);

                            console.log('kddi_unit', customer.name, kddi_call_unit_price, subtotals);

                            if (!zip_folder) {
                                subtotal_rows = subtotal_rows.concat(subtotals);
                            } else {
                                //  電話番号ごとの通話料金小計のzipを作成
                                this.addZipFile(zip_folder, 'subtotal.csv', consts.SUBTOTAL_HEADER, subtotals);
                            }

                            if (error_subtotals && error_subtotals.length > 0) {
                                //  KDDIからの請求見込み額が大きいとき
                                
                                if (!zip_folder) {
                                    error_subtotal_rows = error_subtotal_rows.concat(error_subtotals);
                                } else {
                                    //  エラーとなっている通話料金小計のzipを作成
                                    this.addZipFile(zip_folder, 'error_subtotal_' + customer.name + ".csv", consts.SUBTOTAL_HEADER, error_subtotals);
                                }
                                //  エラーを通知できるようにお客様名を保存
                                price_error_customers.push(customer.name);
                            }

/*
                            //  秒数合計を計算
                            let sec = common.sumAsTime(customerRow, indexes.TIME);
                            let min = common.round(sec / 60, 100);  //  分数は少数第２まで
                            if (kddi_call_unit_price) {
                                let mobile = kddi_call_unit_price.mobile;
                                console.log('mobile', mobile);
                                let price = min * kddi_call_unit_price.mobile;
                                price = Math.round(price);

                                console.log('通話時間合計', customer, customer_id, sec, min, price);
                            }
                            */

                        }
                    }

                    if (price_error_customers.length > 0) {
                        //  KDDIからの請求見込み額が大きいとき
                        //  お客様一覧を表示
                        common.toastError( '以下のお客様への請求額はKDDIからの請求額より少なく計算されました。エラー小計(error_subtotal_**.csv)を確認してください。\r\n' + price_error_customers.join('\r\n'));
                    }
                    if (meisai_error_customers.length > 0) {
                        //  KDDIからの請求見込み額が大きいとき
                        //  お客様一覧を表示
                        common.toastError( '以下のお客様への請求額はKDDIからの請求額より少なく計算されました。エラー明細(error_meisai_**.csv)を確認してください。\r\n' + meisai_error_customers.join('\r\n'));
                    }

                    if (errors.length > 0) {
                        //  エラー表示して終わる
                        zip = null;
                        common.toastError( errors.join('\r\n'));
                    } else {
                        if (this.is_output_all()) {
                            let zip_folder = zip.folder(all_output_folder_name);
                            if (meisai_rows.length > 0) {
                                this.addZipFile(zip_folder, 'meisai.csv', header, meisai_rows);
                            }
                            if (error_meisai_rows.length > 0) {
                                this.addZipFile(zip_folder, 'error_meisai.csv', header, error_meisai_rows);
                            }
                            if (subtotal_rows.length > 0) {
                                this.addZipFile(zip_folder, 'subtotal.csv', consts.SUBTOTAL_HEADER, subtotal_rows);
                            }
                            if (error_subtotal_rows.length > 0) {
                                this.addZipFile(zip_folder, 'error_subtotal.csv', consts.SUBTOTAL_HEADER, error_subtotal_rows);
                            }
                        }
                    }


                } else {
                    //  エラーなのでダウンロードされないよう
                    zip = null;
                }
                return zip;


            },
            //  配列をCSVテキストにしてzip
            addZipFile(zip_folder, file_name, header, rows) {
                //  配列からCSVテキストを作成
                let csv_text = header + '\r\n' + common.arrayToCSVText(rows);

                //  zipを作成
                zip_folder.file(file_name, csv_text);

            },
            getHinmokuMessage(item_names, row, index_hinmoku) {
                let item_code = row[index_hinmoku];
                let item_name = item_names ? item_names.find(item_name => {return item_name.id == item_code;}) : null;
                return item_name ? ' 品目[' + item_name.name + ']' : '';
            },
            //  日割り計算ように割合を取得
            calcPercent(seikyu_date, target_on, is_start) {
                let percent = 1;

                if (target_on && seikyu_date) {
                    let month_date = 0;
                    seikyu_date = new Date(seikyu_date);
                    let started_on_date = new Date(target_on);
                    let year = seikyu_date.getFullYear();
                    let month = seikyu_date.getMonth();
                    if (year == started_on_date.getFullYear() 
                        && month == started_on_date.getMonth() ) {
                        //  開始月なので、日割り計算になるように
                        //  対象月の日数をもとめる
                        month_date = new Date(year, month + 1, 0).getDate();

                        let date = started_on_date.getDate();

                        let days = is_start ? (month_date - date) : date;

                        percent = days / month_date;

                    }

//                    console.log('started_on', target_on, year, month, started_on_date, percent, month_date);
                }
                return percent;
            },
            //  基本料金の明細行を生成
            calcBaseInvoice(customer, units, rows, index_key, index_tel, price_index, code_index, index_hinmoku, price_names, item_names, key_to_customer_tels)
            {
//                console.log('customers units', customer, customer.name, units, rows );
                let new_rows = [];
                let errors = [];
                        
                for (let index in rows) {
                    let row = rows[index];
                    let key = row[index_key];
                    let search_key = common.toSearchTel(key);
                    let tel = row[index_tel];
                    let price = Number(row[price_index]);
                    let code = row[code_index];
                    let code_name = '';
                    let customer_tel = key_to_customer_tels[search_key];   //  カスタマーTEL(追加ch数などの契約情報)

                    //  コードに対応する基本料金の名前と単位情報を取得
                    let price_name = price_names.find(price_name => {return price_name.id == code;});

//                    console.log('customer_tel', customer_tel, key, key_to_customer_tels);
                    let percent = 1;
                    if (price_name.price_type == 2) {
                        //  ランニングコストのとき
                        //  開始月用に日割り計算割合を求める
                        percent = this.calcPercent(this.seikyu_date, customer_tel.started_on, true);
                        if (percent == 1) {
                            //  終了月用に日割り計算割合を求める
                            percent = this.calcPercent(this.seikyu_date, customer_tel.ended_on, false);
                        }
                    }


                    if (!price_name) {
                        let code_type_name = customer.type == 1 ? 'HD統合明細料金コード' : 'FC基本工事料金コード';
                        //  コードがマスター設定されていないとき
                        //  エラーを保存
                        errors.push('コード[' + code + ']に対応する' + code_type_name + 'が設定されていません');

                    } else {
//                        console.log('price_name', price_name);
                        code_name = common.getNameFromPriceName(price_name);
                        //  項目に対しての請求個数を計算
                        let item_count = this.calcItemCount(price_name.unit_type, customer_tel);

//                        console.log('code', code,  code === consts.SENTAKU_CODE ? true : false);
                        if (this.isSpecialCodeForNfc(customer, code)) {
                            //  NFC用の特殊処理　（FCの電話番号数をitem_countとして計算）
                            item_count = null;
                            //  
                            let hd_customer_id = customer.id;
                            if (this.hd_to_fc_for_nfc[hd_customer_id] !== undefined) {
                                let fc_customer_id = this.hd_to_fc_for_nfc[hd_customer_id];
                                if (this.nfc_fc_id_to_count[fc_customer_id] !== undefined) {
                                    item_count = this.nfc_fc_id_to_count[fc_customer_id];
                                } else {
                                    errors.push('お客様[' + customer.name + ']に対応するFC(ID:' + fc_customer_id+ ')のお客様番号レコード数を取得できていません');
                                }
                            } else {
                                errors.push('お客様[' + customer.name + ']に対応するFCお客様が設定されていません');
                            }
                            console.log('NFC用の特殊処理', item_count);
                        }

                        //  請求コードの一致する単価設定を取得
                        let unit = units.find(unit => { 
                            return unit.price_name_id == code;
                        });
                        if (!unit) {
                            //  単価設定されていないとき
                            //  エラーを保存
                            let hinmoku_message = this.getHinmokuMessage(item_names, row, index_hinmoku);
                            errors.push('お客様[' + customer.name + ']にコード[' + code + ':' + code_name + ']' + hinmoku_message + 'に対応する基本料金請求単価が設定されていません')
                        } else if(item_count === null) {
                        } else {
                            let new_price = Math.floor(item_count * unit.unit_price * percent);
                            let new_row = [
                                customer.id,
                                key, tel, code, code_name, price, item_count, unit.unit_price, new_price, price > new_price ? 'X' : ''
                            ];

                            new_rows.push(new_row);

                       //     console.log('calc', code_name, code, price_name, key, customer_tel, item_count, unit.unit_price, item_count * unit.unit_price);
                        }
                    }
                }
                if (errors.length > 0) {
                    //  単価設定されていないエラー表示して終わる
                    new_rows = null;
                    common.toastError( errors.join('\r\n'));

                }
                return new_rows;

            },
            //  NFC用の特殊処理が必要なとき
            isSpecialCodeForNfc(customer, code) {
                return  (code == consts.SENTAKU_CODE && this.hd_to_fc_for_nfc[customer.id] !== undefined) ? true : false;
            },
            //  項目に対しての請求個数を計算
            calcItemCount(unit_type, customer_tel) {
                let item_count = 1;
                if (unit_type == consts.UNIT_TYPE.ADDITIONAL_CHANNEL) {
                    //  項目が追加chのときは、customer_telに設定されている追加ch数
                    item_count = customer_tel.additional_channel_count;
                } else if (unit_type == consts.UNIT_TYPE.ADDITIONAL_TEL) {
                    //  項目が追加番号のときは、customer_telに設定されている追加番号数
                    item_count = customer_tel.additional_tel_count;
                } else if (unit_type == consts.UNIT_TYPE.TEL) {
                    //  項目が番号のときは、customer_telに設定されている追加番号数+1
                    item_count = customer_tel.additional_tel_count + 1;
                }
                if (item_count == 0) {
                    //  0にはならないように調整
                    item_count = 1;
                }
                return item_count;

            },

            //  明細一覧の明細ごとにCXからお客様への請求額を計算
            //  明細行に計算結果の列を追加して請求額を設定
            calcCxInvoice(type, rows, call_unit_price, tel_index, time_index, price_index, call_type_index, new_meisai_indexes, customer) {
                let new_rows = [];  //  計算後の明細一覧
                let tel_to_call_type_to_subtotals = {};     //  電話番号ごとコール種別ごとの小計
                let error_rows = [];

                for (let index in rows) {
                    let row = rows[index];

                    let time_text = row[time_index];
                    if (type == consts.CONTRACT.FC) {
                        //  フリーコールのときは、hh:mm:ss.s形式に変換
                        time_text = common.toHH_MM_SS_s(time_text);
                        row[time_index] = time_text;
                    }
                    // hh:mm:ss.sを秒数に変換
                    let sec = common.toSec(time_text);

                    let price = row[price_index];

                    let call_type_text = row[call_type_index];
                    let call_type;
                    if (type == consts.CONTRACT.HD) {
                        call_type = this.getHdCallType(call_type_text);
                    } else {
                        call_type = this.getFcCallType(call_type_text);
                        // FCの場合。priceは 8.5円のときは、850と設定されているので、8.5になるようにする
                        price = price / 100;
                        row[price_index] = price;
                    }


                    let [min, unit_price, new_price, error_char, is_kddi_ip] = this.calcCxPrice(sec, call_type, call_unit_price, price);


                    //  計算結果の列を追加して請求額を設定
                    let new_row = this.create_new_meisai(row, new_meisai_indexes, min, unit_price, new_price, customer);

                    if (price > new_price) {
                        //  kddiのほうがcxからの請求より大きい場合はワーニングを出す
//                        error_rows.push(new_row);         //  明細は、KDDIの約定料金との比較になり、全部エラーになってしまうのでチェックしない
                        if (error_char == null) {
                            error_char = 'X';
                        }
                    }
                    //  kddiの方が大きいときは、最後の列にXが表示されるように
//                    new_row.push(is_error_row ? 'X' : '');

                    new_rows.push(new_row);


                    let tel = row[tel_index];

                    if (!is_kddi_ip) {  //  KDDI内IPは小計計算にふくめない
                        //  電話番号ごとの小計を計算
                        this.updateSubTotal(tel_to_call_type_to_subtotals, tel, sec, min, unit_price, new_price, call_type, call_type_text);

                    }

/*
                    let target_price = new_price;
                    if (type == consts.CONTRACT.FC) {
                        // FCの場合。priceは 8.5円のときは、850と設定されているので、new_priceを100倍した値で計算する
                        target_price = new_price*100;
                    }
                    if (price > target_price) {
                    */



                }


                return [new_rows, tel_to_call_type_to_subtotals, error_rows];

            },
            //  電話番号ごとコール種別ごとの小計を計算
            //  　電話番号ごとに、通話種別ごとの秒数情報も更新する（KDDIからの見込み請求額を計算するため）
            updateSubTotal(tel_to_call_type_to_subtotals, tel, sec, min, unit_price, new_price, call_type, call_type_text) {
                let call_type_to_subtotals;
//                new_price = Number(new_price);
                if (tel_to_call_type_to_subtotals[tel] === undefined) {
                    call_type_to_subtotals = {};
                } else {
                    call_type_to_subtotals = tel_to_call_type_to_subtotals[tel];
                }
                this.updateSubTotalByCallType(call_type_to_subtotals, call_type, sec, min, unit_price, new_price, call_type_text);

                tel_to_call_type_to_subtotals[tel] = call_type_to_subtotals;
//                console.log('update subtotal', tel, row);

            },
            //  通話種別ごとの小計情報を更新
            updateSubTotalByCallType(subtotal_by_call_type, call_type, sec, min, unit_price, new_price, call_type_text) {
                let subtotal;
                if (subtotal_by_call_type[call_type] === undefined) {
                    subtotal = {
                        'sec': sec,
                        'count': 1,
                        'min': min,
                        'unit_price': unit_price,
                        'new_price': new_price,
                        'call_type_text' : call_type_text,
                    };
                } else {
                    subtotal = subtotal_by_call_type[call_type];
                    subtotal.sec = subtotal.sec + sec;
                    subtotal.count = subtotal.count + 1;
                    subtotal.min = subtotal.min + min;
//                    subtotal.new_price = subtotal.new_price + new_price;    //  何故かこれだと、少数計算で微妙なずれが生じてしまうので、分 x 単価で計算しなおす
                    subtotal.new_price = common.bigmul(subtotal.min, unit_price);

                }
                subtotal_by_call_type[call_type] = subtotal;
                return subtotal_by_call_type;
            },
            /*
            //  通話種別ごとの秒数情報を更新
            updateCallTypeSec(call_type, sec, sec_by_call_type) {
                if (sec_by_call_type[call_type] === undefined) {
                    sec_by_call_type[call_type] = sec;
                } else {
                    sec_by_call_type[call_type] += sec;
                }
                return sec_by_call_type;
            },
            */
            makeSubTotal(tel_to_call_type_to_subtotals, kddi_call_unit_price, customer) {
                let new_subtotals = [];
                let error_subtotals = [];

                for(let tel in tel_to_call_type_to_subtotals) {
                    let call_type_to_subtotal = tel_to_call_type_to_subtotals[tel];
                    for (let call_type in call_type_to_subtotal) {
                        let subtotal = call_type_to_subtotal[call_type];

                        //  kddiからの見込み請求額を計算
                        let [kddi_min, kddi_unit_price, kddi_price, error_char, is_kddi_ip] = this.calcCxPrice(subtotal.sec, call_type, kddi_call_unit_price, null);

                        //  'お客様ID, 課金電話番号,通話種別ID,通話種別,通話秒数計,KDDI分数計,KDDI単価,KDDIからの見込み通話料,通話回数,CX分数計,CX単価,CX通話料';
                        let subtotal_row = [customer.id, tel, call_type, subtotal.call_type_text, subtotal.sec, kddi_min, kddi_unit_price, kddi_price, subtotal.count, subtotal.min, subtotal.unit_price, subtotal.new_price];
                        if (kddi_price > subtotal.new_price) {
                            //  kddiからの請求見込み額のほうが大きいとき。アラートが表示されるように
                            if (error_char == null) {
                                error_char = 'X';
                            }
                        }
                        if (error_char != null) {
                            subtotal_row.push(error_char);
                            error_subtotals.push(subtotal_row);
                        } else {
                            subtotal_row.push('');
                        }
                        new_subtotals.push(subtotal_row);
//                        console.log('subtotal', kddi_price, subtotal.new_price);

                    }


                }
                return [new_subtotals, error_subtotals];
            },
            //   一括出力かどうか
            is_output_all() {
                return this.output_type == 'all' ? true : false;
            },
            //  もとの明細から出力する明細を生成
            create_new_meisai(row, new_meisai_indexes, min, unit_price, new_price, customer) {
                let new_row = [];
                if (new_meisai_indexes != null) {
                    new_row.push(customer.id);  //  お客様IDを先頭列に追加
                    new_meisai_indexes.forEach(index => {
                        new_row.push(row[index]);
                    });

                } else {
                    new_row = row;
                }
                //  もとの明細の終わりに通話分数、単価、CX通話料を追加
                new_row.push(min);
                new_row.push(unit_price);
                new_row.push(new_price);
                return new_row;

            },
            //  CXからお客様への請求額を計算
            calcCxPrice(sec, call_type, call_unit_price, price)
            {

                let min = 0;
                let unit_price = '';
                let error_char = null;
                let is_kddi_ip = false;
                if (call_type == consts.PHONE.FIXED_LINE) {
                    //  固定電話
                    unit_price = call_unit_price.fixed_line;
                    
                } else if (call_type == consts.PHONE.MOBILE) {
                    //  携帯電話
                    unit_price = call_unit_price.mobile;
                } else if (call_type == consts.PHONE.PHS) {
                    //  phs
                    unit_price = call_unit_price.phs;
                } else if (call_type == consts.PHONE.PUBLIC_LINE) {
                    //  公衆電話
                    unit_price = call_unit_price.public_line;
                } else if (call_type == consts.PHONE.INS) {
                    //  INS端末
                    unit_price = call_unit_price.ins;
                } else if (call_type == consts.PHONE.IP) {
                    //  IP電話
                    unit_price = call_unit_price.ip;

                    if (price === 0) {
                        // KDDI内のIP電話（KDDIからの請求額が０）のときは、単価設定されていても０円にする
                        unit_price = 0;
                        is_kddi_ip = true;
                    }
                } else {
                    //  その他
                    //    未設定の場合はunit_priceを0にする
                    unit_price = 0;

                }
                if (unit_price === '' || unit_price === null) {
                    unit_price = 0;
                    error_char = 'A';   //  単価設定されていないか、想定外の単価の場合は、エラーAとする
                }

                //  

                //  分を切り上げ
                min = Math.ceil(sec/60);

                if (unit_price !== '') {
                    //  少数第2位で四捨五入。そのままかけると誤差が出てしまう。javascriptの仕様上少数計算は誤差がでる
                    price = common.bigmul(min, unit_price);
//                    console.log('cx 請求計算 秒 分 価格', call_type, sec, min, price);
                }

                return [min, unit_price, price, error_char, is_kddi_ip];

            },
            getHdCallType(call_type_text) {
                if (call_type_text.indexOf('国内呼') === 0) {
                    return consts.PHONE.FIXED_LINE;
                } else if (call_type_text.indexOf('携帯呼') === 0) {
                    return consts.PHONE.MOBILE;
                } else {
                    return 0;
                }


            },
            getFcCallType(call_type_text) {
                let convert = {
                    'S': consts.PHONE.FIXED_LINE,
                    'C': consts.PHONE.MOBILE,
                    'A': consts.PHONE.PHS,
                    'X': consts.PHONE.PUBLIC_LINE,
                    'N': consts.PHONE.INS,
                    'U': consts.PHONE.IP,
                };
                if (convert[call_type_text] !== undefined) {
                    return convert[call_type_text];
                } else {
                    return 0;
                }

            },


            //  顧客ごとにまとめる
            groupByCustomer(rows, key_index, key_to_customer_tels, type, meisai_or_base) {
                let customerRows = {};
                let errors = [];
                let tel_errors = [];
                for (let i = 0; i < rows.length; i++) {
                    let row = rows[i];
                    let key = row[key_index];
                    let search_key = common.toSearchTel(key);
//                    console.log('searchTel', search_key);
                    if (key_to_customer_tels[search_key]) {
                        //  顧客が決定
                        let customer_tel = key_to_customer_tels[search_key];
                        let customer_id = customer_tel.customer_id;

                        let customer = this.findCustomer(customer_id);

                        if (customer && customer.type == type) {
                            let customerRow = null;
                            /*
                            if (this.is_output_all()) {
                                //  一括出力のときは、一つのカスタマーとするためとりあえず先頭カスタマーとして保存
                                customer_id = this.customers[0].id;
                            }
                            */
                            if (customerRows[customer_id]) {
                                customerRow = customerRows[customer_id];
                            } else {
                                customerRow = [];
                            }
                            customerRow.push(row);
                            customerRows[customer_id] = customerRow;

                        } else {
                            errors.push(consts.contractName(type) + '明細の課金番号[' + key + ']に異なる契約種別のお客様[' + customer.name + ']が登録されています');
                        }


                    } else {
                        tel_errors.push(key);
//                        errors.push('登録されていない電話番号[' + tel + ']の請求が存在しています。この電話番号をお客様電話番号に登録してください');
                    }
                }
                if (tel_errors.length > 0) {
                    tel_errors = Array.from(new Set(tel_errors));   //  重複を取り除く
                    let error = consts.contractName(type) + 'に登録されていない契約コードまたは電話番号の請求が発生しています。以下をお客様番号に登録してください。\r\n' + tel_errors.join(',');
                    errors.push(error);
                }
                if (errors.length > 0) {
                    //  エラー表示して終わる
                    customerRows = null;
                    common.toastError((meisai_or_base == 1 ? '明細' : '基本') + '料金計算\r\n' +  errors.join('\r\n'));

                }
                return customerRows;
            },
            /*
            loadFcPriceNames() {
                  this.$Progress.start();
                  axios.get('/api/fc_price_name_all_as_key_value').then( result => {
                      this.fc_priceNames = result.data.data;
                      console.log('fc_priceNames', this.fc_priceNames);
                  });
                  this.$Progress.finish();
            },
            loadHdPriceNames() {
                  this.$Progress.start();
                  axios.get('/api/hd_price_name_all_as_key_value').then( result => {
                      this.hd_priceNames = result.data.data;
                      console.log('hd_priceNames', this.hd_priceNames);
                  });
                  this.$Progress.finish();
            },
            */

/*
            loadTels() {

                  this.$Progress.start();
                  
                  axios.get('/api/customer_tel_all_as_key_value').then( result => {
                      this.tels = result.data.data;
//                      console.log('tels', this.tels);
                      this.loadAllCustomer();

                  });

                  this.$Progress.finish();
            },
            */
            loadAllCustomer() {
                axios.get('/api/customer_all').then( result => {
                    this.customers = result.data.data;

                    this.customers.forEach(customer => {
                        let customer_tels = customer.customer_tel;
                        
                        customer_tels.forEach(customer_tel => {
                            if (customer.type == consts.CONTRACT.HD) {
                                //  光ダイレクト
                                let contract_code = customer_tel.contract_code;
                                if (contract_code) {
                                    this.contract_codes[contract_code] = customer_tel;
                                }
                                this.hd_tels[customer_tel.tel] = customer_tel;
                            } else {
                                //  FC
                                this.fc_tels[customer_tel.tel] = customer_tel;
                            }

                        });

                    });

//                    console.log('customers', this.customers, this.contract_codes, this.hd_tels, this.fc_tels);

                });

            },
            findCustomer(customer_id) {
                return this.customers.find(customer => customer.id == customer_id);

            },


        },
        mounted() {
        },
        created() {
            this.loadAllCustomer();

            //  請求月を先月に設定
            let date = new Date();
            let last_month = new Date(date.getFullYear(), date.getMonth(), 0); // 先月の末日
            this.seikyu_date = last_month.getFullYear() + '-' + ('00' + (last_month.getMonth() + 1)).slice(-2) + '-01'; 

//            this.loadTels();
//            this.loadFcPriceNames();
//            this.loadHdPriceNames();
            //  NFCの選択番号通知利用料の特殊処理用にNFCのFCのお客様IDごとの番号数一覧を取得
            this.getFcTelCountForSentakubango().then(result => {
                if (result.data && result.data.success) {
                    this.nfc_fc_id_to_count = result.data.data;
                }
//                console.log('getFcTelCountForSentakubango', result, this.nfc_fc_id_to_count, this.nfc_fc_id_to_count[11]);
            });


        }
    }
</script>
