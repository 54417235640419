
//  光ダイレクトの明細CSVのインデックス
//  ご利用電話番号,通話開始日時,通話開始時刻,着信電話番号,地域名,通話時間,内線区分,呼種別,通話料
const HD_CSV_INDEX = {
    TEL: 0,       // ご利用電話番号
    I_1: 1,       // 通話開始日時
    I_2: 2,       // 通話開始時刻
    I_3: 3,       //  着信電話番号
    I_4: 4,       // 地域名
    TIME: 5,            //  通話時間
    I_6: 6,                // 内線区分
    CALL_TYPE: 7,       //  呼種別
    PRICE: 8,           //  通話料
//    CX_PRICE: 9,        //  CXからお客様への請求額
};
const HD_MEISAI_HEADER = 'お客様ID,ご利用電話番号,通話開始日時,通話開始時刻,着信電話番号,地域名,通話時間,内線区分,呼種別,KDDI通話料,分数,単価,CX通話料';

//  光ダイレクトの基本料・工事料のインデックス（区分24）
const HD_CSV_BASE_INDEX = {
  KAKIN: 4,         // 課金番号(契約コード)
  TEL: 5,           // 関連契約番号(基本番号つまり電話番号)
  CODE: 8,          //  統合明細料金コード
  HINMOKU:9,        //  品目コード
  PRICE: 13,        //  統合明細金額  1000円 -> +0000001000
};

//  フリーコールの通信明細のインデックス (レコード区分:20)
const FC_CSV_INDEX = {
  TEL: 4,         // 課金電話番号
  I_9: 9,            //  通話先電話番号
  I_10: 10,            //  通話開始年月日
  I_11: 11,            //  通話開始時刻
  TIME: 12,            //  通話時間
  I_13: 13,            //  発信地域名
  I_14: 14,            //  着信地域名
  CALL_TYPE: 15,       //  呼種別 (発信端末種別)
  PRICE: 26,           //  通話料
//  CX_PRICE: 29,        //  CXからお客様への請求額
};
const FC_MEISAI_HEADER = 'お客様ID,課金電話番号,通話先電話番号,通話開始年月日,通話開始時刻,通話時間,発信地域名,着信地域名,呼種別,KDDI通話料,分数,単価,CX通話料';

const SUBTOTAL_HEADER = 'お客様ID,課金電話番号,通話種別ID,通話種別,通話秒数計,KDDI分数計,KDDI単価,KDDIからの見込み通話料,通話回数,CX分数計,CX単価,CX通話料,エラー';

//  FCの基本料・工事料のインデックス（区分22）
const FC_CSV_BASE_INDEX = {
  TEL: 4,         // 課金電話番号
  CODE: 5,        //  基本工事料金コード
  PRICE: 6,           //  金額  1000円 -> +0000001000
};

const CONTRACT = {
    HD: 1,          //  光ダイレクト
    FC: 2,          //  フリーコール
}

const INVOICE = {
  KDDI: 1,      //  KDDIからCXへの請求
  CX: 2,      //  CXからお客様への請求
}

const PHONE = {
    FIXED_LINE: 1,   //  固定電話
    MOBILE: 2,       //  携帯
    PHS: 3,          //  PHS
    PUBLIC_LINE: 4,  //  公衆電話
    INS: 5,  //  INS端末
    IP: 6,  //  IP電話
}

//  単位種別\n1:契約・回線、2:追加ch、3:追加番号、4:番号(追加番号+1)
const UNIT_TYPE = {
    CONTRACT: 1,            // 1:契約・回線
    ADDITIONAL_CHANNEL: 2,  // 2:追加ch
    ADDITIONAL_TEL: 3,      // 3:追加番号
    TEL: 4,                 // 4:番号(追加番号+1)
}

const SENTAKU_CODE = '24B00004L7';  //	選択番号通知利用料のコード

function contractName(type)
{
  if (type == CONTRACT.HD) {
    return '光ダイレクト'
  }
  if (type == CONTRACT.FC) {
    return 'フリーコール'
  }
  return '';
}
function invoiceName(type)
{
  if (type == INVOICE.KDDI) {
    return 'KDDIからCXへの請求'
  }
  if (type == INVOICE.CX) {
    return 'CXからお客様への請求'
  }
  return '';
}

export default {
    HD_CSV_INDEX,
    HD_MEISAI_HEADER,
    HD_CSV_BASE_INDEX,
    FC_CSV_INDEX,
    FC_MEISAI_HEADER,
    FC_CSV_BASE_INDEX,
    CONTRACT,
    INVOICE,
    PHONE,
    contractName,
    invoiceName,
    UNIT_TYPE,
    SUBTOTAL_HEADER,
    SENTAKU_CODE,
}