<template>
  <price-name :API="'/api/fc_price_name'" :title="'FC基本工事料金コード'"  :model="'App\\Models\\FcPriceName'"/>
</template>

<script>
import PriceName from './PriceName.vue'

    export default {
        components: {
            PriceName,
        },

        mounted() {
        },
        created() {
        }
    }
</script>
