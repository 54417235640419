
function doResponse(response, is_show_success = true) {
    let result;
    console.log('doResponse', response);
    console.log('doResponse success', is_show_success, response.data.success);
    if(response.data.success){
      if (is_show_success) {
        // success
        Toast.fire({
          icon: 'success',
          title: response.data.message
        });

      }
      result = true;

    } else {
      Toast.fire({
          icon: 'error',
          showConfirmButton: true,
          timer: 0,
          title: response.data.message
      });
      result = false;
    }
    return result;
}

function doResponseFire(response, successTitle, failTitle) {
    let result;
    if(response.data.success){
        // success
        Swal.fire(successTitle, response.data.message, 'success');
        result = true;

    } else {
        Swal.fire(failTitle, response.data.message, "warning");
        result = false;
    }
    return result;
}

function confirmDelete(okFunc, params, title = null, buttonTitle = null) {
  if (!title) {
    title = '本当に削除してよろしいですか？';
  }
  if (!buttonTitle) {
    buttonTitle = '削除'
  }
  Swal.fire({
    title: title,
    text: "この操作を取り消すことはできません",
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: buttonTitle,
    cancelButtonText: 'キャンセル'
    }).then((result) => {

        if (result.value) {
          okFunc(params);
        }
    })

}
function confirmOK(title, text, okFunc, params) {
  Swal.fire({
    title: title,
    text: text,
    showCancelButton: false,
//    confirmButtonColor: '#d33',
    confirmButtonText: 'OK',
    }).then((result) => {

        if (result.value) {
          if (okFunc) {
            okFunc(params);
          }
        }
    })

}

function toastError(message) {
  Toast.fire({
    icon: 'error',
    showConfirmButton: true,
    timer: 0,
    title: message
  });
}



function toShiftJis(csv) {
  csv = new Encoding.stringToCode(csv)
  csv = Encoding.convert( csv, 'SJIS');
  csv = new Uint8Array( csv );
  return csv;
}

//  ファイルの先頭行を取得
function readHeader(fileInfo) {
  return new Promise((resolve, reject) => {
    let file_reader = new FileReader();

    // ファイルの読み込みを行ったら実行
    file_reader.addEventListener('load', function(e) {
      var array = new Uint8Array(e.target.result);
    // 文字コードを取得
    switch (Encoding.detect(array)) {
      case 'UTF16':
          // 16ビット符号なし整数値配列と見なす
          array = new Uint16Array(e.target.result);
          break;
      case 'UTF32':
          // 32ビット符号なし整数値配列と見なす
          array = new Uint32Array(e.target.result);
          break;
      }
  
      // Unicodeの数値配列に変換
      var unicodeArray = Encoding.convert(array, 'UNICODE');
      // Unicodeの数値配列を文字列に変換
      var text = Encoding.codeToString(unicodeArray);
  
//      console.log(text); // 結果
  
        let header = null;
        let lines = text.split(/\r\n|\n/);
        if (lines.length > 0) {
            header = lines[0];
        }
        resolve(header);
      });

    file_reader.readAsArrayBuffer(fileInfo);

  });
}
function baseName(str)
{
   var base = new String(str).substring(str.lastIndexOf('/') + 1); 
    if(base.lastIndexOf(".") != -1)       
        base = base.substring(0, base.lastIndexOf("."));
   return base;
}

///  csv文字列から配列を生成
function makeArrayFromCSVText(csvText, isExistHeader)
{
    let newRows = [];
    let rows = csvText.split(/\r\n|\n/);
  
    for (let i= isExistHeader ? 1 : 0; i < rows.length; i++) {

        let row = rows[i];
        if (row.length > 0) {
            row = row.split(',');
            newRows.push(row);
        }
    }
    return newRows;
}

//  配列をCSVテキストに変換
//  配列の要素にカンマは含まない前提
function arrayToCSVText(rows)
{
  let csvText = '';
  for(let i in rows) {
    let row = rows[i];
//    csvText += row.join(',') + "\r\n";
    csvText += '"' + row.join('","') + '"\r\n';
  }
  return csvText;

}
/**
 * CSVの一行を引数に指定すると、
 * ""で囲まれた内部のコンマを分割せずに、
 * コンマで分割して配列の戻り値を返す関数
 * @param {string} line 文字列
 * @return {Array<string>} 配列
 */
function csvSplit(line) {
    var c = "";
    var s = new String();
    var data = new Array();
    var singleQuoteFlg = false;

    for (var i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (c == "," && !singleQuoteFlg) {
        data.push(s.toString());
        s = "";
        } else if (c == "," && singleQuoteFlg) {
        s = s + c;
        } else if (c == '"') {
        singleQuoteFlg = !singleQuoteFlg;
        } else {
        s = s + c;
        }
    }
    if (s.length > 0) {
        data.push(s.toString());
    }
    return data;
}

function readTextFile(fileInfo) {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
  
        // ファイルの読み込みを行ったら実行
        fileReader.onload = function ( e ) {
            let array = new Uint8Array(e.target.result);
            // 文字コードを取得
            switch (Encoding.detect(array)) {
                case 'UTF16':
                    // 16ビット符号なし整数値配列と見なす
                    array = new Uint16Array(e.target.result);
                    break;
                case 'UTF32':
                    // 32ビット符号なし整数値配列と見なす
                    array = new Uint32Array(e.target.result);
                    break;
            }
            
            // Unicodeの数値配列に変換
            let unicodeArray = Encoding.convert(array, 'UNICODE');
            // Unicodeの数値配列を文字列に変換
            let text = Encoding.codeToString(unicodeArray);
    
  //      console.log(text); // 結果
    
//            let lines = text.split(/\r\n|\n/);

            resolve(text);
        };
  
        fileReader.readAsArrayBuffer(fileInfo);
  
    });
  }


//  hh:mm:ss.s を秒数に変換
function toSec(hhmmss)
{
    let h_m_s = hhmmss.split(':');
    let sec = 0;
    let mul = 1;
    for (let i = h_m_s.length - 1; i >= 0; i--) {
        let value = h_m_s[i];
        sec = sec +  (value * mul);
        mul = mul * 60;
    }
    return sec;
}
// hhmmsssをhhh:mm:ss.sに変換
function toHH_MM_SS_s(hhhmmss)
{
  let result = '';
  for (let i = hhhmmss.length - 1; i >= 0; i--) {

    if (result.length == 1) {
      result = '.' + result;
    }
    if (result.length == 4 || result.length == 7) {
      result = ':' + result;
    }

    let c = hhhmmss.charAt(i);
    result = c + result;
  }
  return result;
}
//  hh:mm:ss.s形式の時間を秒数に変換して合計
function sumAsTime(rows, time_index)
{
    let sum = 0;
    for (let i in rows) {
        let row = rows[i];
        let sec = toSec(row[time_index]);
        sum = sum + sec;
    }
    return sum;
}
    
/**
 * 任意の桁で四捨五入する関数
 * @param {number} value 四捨五入する数値
 * @param {number} base どの桁で四捨五入するか（10→小数第１位, 100→小数第2位）
 * @return {number} 四捨五入した値
 */
 function round(value, base) {
  return Math.round(value * base) / base;
}

/**
* 任意の桁で切り上げする関数
* @param {number} value 切り上げする数値
* @param {number} base どの桁で切り上げするか（10→小数第１位, 100→小数第2位）
* @return {number} 切り上げした値
*/
function ceil(value, base) {
  return Math.ceil(value * base) / base;
}

/**
* 任意の桁で切り捨てする関数
* @param {number} value 切り捨てする数値
* @param {number} base どの桁で切り捨てするか（10→小数第１位, 100→小数第2位）
* @return {number} 切り捨てした値
*/
function floor(value, base) {
  return Math.floor(value * base) / base;
}

//  テキストファイルダウンロード
function download_txt(file_name, data) {
 
  const blob = new Blob([data], {type: 'text/plain'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.download = file_name;
  a.href = url;
  a.click();
  a.remove();
  URL.revokeObjectURL(url);

}

//  電話番号のハイフンを取り除く
function toSearchTel(tel)
{
  //  電話番号からスペースとハイフンを取り除く
  return tel.replace(/-| /g, '');
}

function getNameFromPriceName(price_name) {
  let name = null;
  if (price_name) {
    if (price_name.display_name && price_name.display_name.length > 0) {
      name = price_name.display_name;
    } else {
      name = price_name.name.trim();
    }
  }
  return name;
}

//  少数第2位で四捨五入
//  javascriptの掛け算は誤差がでるので
function bigmul(num, value)
{
  return Math.round(num * value * 100) / 100 
}

export default {
    doResponse,
    doResponseFire,
    toShiftJis,
    confirmDelete,
    confirmOK,
    toastError,
    makeArrayFromCSVText,
    arrayToCSVText,
    csvSplit,
    readHeader,
    baseName,
    readTextFile,
    toSearchTel,
    sumAsTime,
    toSec,
    toHH_MM_SS_s,
    round,
    ceil,
    floor,
    download_txt,
    getNameFromPriceName,
    bigmul,
    
 
}