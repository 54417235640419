<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <div class="card"><!-- 検索-->
              <div class="card-header">
                <h3 class="card-title">検索条件</h3>

                <div class="card-tools">
                  <button type="button" class="btn  btn-sm btn-primary" @click="resetSearchParams()">
                      クリア
                  </button>

                    <button type="button" class="btn  btn-sm btn-primary" @click="getRows()">
                        <i class="fa fa-search"></i>
                        検索
                    </button>
                </div>
              </div>
              <div class="card-body">

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">契約種別</label>
                        <select  class="form-control" v-model="searchParams.type" name="type">
                            <option :value="''"></option>
                            <option :value="consts.CONTRACT.HD">光ダイレクト</option>
                            <option :value="consts.CONTRACT.FC">フリーコール</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">名前</label>
                        <input v-model="searchParams.name" type="text" name="name"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">カナ</label>
                        <input v-model="searchParams.name_kana" type="text" name="name_kana"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">光ダイレクトID</label>
                        <input v-model="searchParams.contract_id" type="text" name="contract_id"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card 検索-->
          </div>


          <div class="col-9">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">{{title}}一覧<span v-if="rows && rows.total">({{rows.total + '件中の' + rows.from + '〜'  + rows.to + '件' }})</span></h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="onCreate()">
                      <i class="fa fa-plus-square"></i>
                      追加
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>名前</th>
                      <th>契約種別</th>
                      <th>操作</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="row in rows.data" :key="row.id">

                      <td>{{row.id}}</td>
                      <td>{{row.name}}</td>
                      <td>{{consts.contractName(row.type)}}</td>
                      <td>
                        <a href="#" @click="onUpdate(row)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#" @click="onDelete(row)">
                            <i class="fa fa-trash red"></i>
                        </a>
                        </span>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer clearfix">
                  <pagination :data="rows" :limit="5" @pagination-change-page="getRows"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">新規作成</h5>
                    <h5 class="modal-title" v-show="editmode">編集</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateRow() : createRow()">
                    <div class="modal-body">
                            <div class="form-group">
                                <label>契約種別</label>
                                <div class="form-check">
                                    <label class="form-check-label" ><input :disabled="editmode" type="radio"  :value="consts.CONTRACT.HD" v-model="form.type" >
                                    光ダイレクト&nbsp;</label>
                                    <label class="form-check-label" ><input :disabled="editmode" type="radio"  :value="consts.CONTRACT.FC" v-model="form.type" >
                                    フリーコール&nbsp;</label>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label>名前</label>
                                <input v-model="form.name" type="text" name="name"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                                <has-error :form="form" field="name"></has-error>
                            </div>
                            <div class="form-group">
                                <label>カナ</label>
                                <input v-model="form.name_kana" type="text" name="name_kana"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('name_kana') }">
                                <has-error :form="form" field="name_kana"></has-error>
                            </div>
                            <div v-if="form.type == consts.CONTRACT.HD" class="form-group">
                                <label>光ダイレクトID</label>
                                <input v-model="form.contract_id" type="text" name="contract_id"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('contract_id') }">
                                <has-error :form="form" field="contract_id"></has-error>
                            </div>
                            <div v-if="form.type != ''" class="form-group">
                                <label>{{consts.invoiceName(consts.INVOICE.KDDI)}}</label>
                                <select class="form-control" v-model="form.kddi_call_unit_price[0].id">

                                    <option :value="''"></option>
                                    <option 
                                    v-for="call_unit_price in getCallUnitPrices(form.type, consts.INVOICE.KDDI)" :key="call_unit_price.id"
                                    :value="call_unit_price.id"
                                    >{{ call_unit_price.name }}</option>
                                </select>
                            </div>
                            <div v-if="form.type != ''" class="form-group">
                                <label>{{consts.invoiceName(consts.INVOICE.CX)}}</label>
                                <select class="form-control" v-model="form.cx_call_unit_price[0].id">

                                    <option :value="''"></option>
                                    <option 
                                    v-for="call_unit_price in getCallUnitPrices(form.type, consts.INVOICE.CX)" :key="call_unit_price.id"
                                    :value="call_unit_price.id"
                                    >{{ call_unit_price.name }}</option>
                                </select>

                            </div>
                            <div v-if="form.type != ''" class="form-group">
                                <label>CXからお客様への請求（基本料金請求単価）</label>
                                <select class="form-control" v-model="form.base_unit_price[0].id">

                                    <option :value="''"></option>
                                    <option 
                                    v-for="base_unit_price in getBaseUnitPrices(form.type)" :key="base_unit_price.id"
                                    :value="base_unit_price.id"
                                    >{{ base_unit_price.name }}</option>
                                </select>

                            </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        <button v-show="editmode" :disabled="!checkValidate(false)" type="submit" class="btn btn-success">更新</button>
                        <button v-show="!editmode" :disabled="!checkValidate(false)" type="submit" class="btn btn-primary">新規作成</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
const THIS_API = '/api/customer';
import common from '../../common.js'
import consts from '../../consts.js'

    export default {
        data () {
            return {
                editmode: false,
                searchParams: {
                  type: '',
                  name: '',
                  name_kana: '',
                  contract_id: '',
                },

              rows : {},
              form: new Form({
                  id : '',
                  type: consts.CONTRACT.HD,             // 契約種別\n1:光ダイレクト、2:フリーコール
                  name: '',             // お客様名
                  name_kana: '',        // お客様名カナ
                  contract_id: '',      // 契約ID。光ダイレクトのときは光ダイレクトID。とりあえず光ダイレクトのときのみ有効
                  kddi_call_unit_price: [{ id: '' }],  //  KDDIからCXへの請求単価グループ
                  cx_call_unit_price: [{ id: '' }],    //  CXからお客様への請求単価グループ
                  base_unit_price: [{ id: '' }],    //  CXからお客様への基本料金請求単価グループ
              }),
              hd_kddi_call_unit_prices: [],
              hd_cx_call_unit_prices: [],
              fc_kddi_call_unit_prices: [],
              fc_cx_call_unit_prices: [],
              hd_base_unit_prices: [],
              fc_base_unit_prices: [],

            }
        },
        computed: {
            title: function() {
                return 'お客様';
            },
            consts: function() {
                return consts;
            }

        },
        methods: {
            loadCallUnitPrices() {
              this.loadCallUnitPricesByType(consts.CONTRACT.HD, consts.INVOICE.KDDI);
              this.loadCallUnitPricesByType(consts.CONTRACT.HD, consts.INVOICE.CX);
              this.loadCallUnitPricesByType(consts.CONTRACT.FC, consts.INVOICE.KDDI);
              this.loadCallUnitPricesByType(consts.CONTRACT.FC, consts.INVOICE.CX);
            },
            loadCallUnitPricesByType(type, invoice_type) {
              let param = 'type=' + type + '&invoice_type=' + invoice_type;
              axios.get('/api/call_unit_price_all?' + param).then( ({data}) => {
                      let rows = data.data;
//                      console.log('call_unit_prices all',  rows, data);
                      if (type == consts.CONTRACT.HD) {
                        if (invoice_type == consts.INVOICE.KDDI) {
                          this.hd_kddi_call_unit_prices = rows;
                        } else {
                          this.hd_cx_call_unit_prices = rows;
                        }
                      } else {
                        if (invoice_type == consts.INVOICE.KDDI) {
                          this.fc_kddi_call_unit_prices = rows;
                        } else {
                          this.fc_cx_call_unit_prices = rows;
                        }
                      }

                  });
            },
            loadBaseUnitPrices() {
              this.loadBaseUnitPricesByType(consts.CONTRACT.HD);
              this.loadBaseUnitPricesByType(consts.CONTRACT.FC);
            },
            loadBaseUnitPricesByType(type) {
              let param = 'type=' + type;
              axios.get('/api/base_unit_price_all?' + param).then( ({data}) => {
                      let rows = data.data;
//                      console.log('call_unit_prices all',  rows, data);
                      if (type == consts.CONTRACT.HD) {
                        this.hd_base_unit_prices = rows;
                      } else {
                        this.fc_base_unit_prices = rows;
                      }

                  });
            },
            getCallUnitPrices(type, invoice_type) {
              let rows = [];
              if (type == consts.CONTRACT.HD) {
                if (invoice_type == consts.INVOICE.KDDI) {
                  rows = this.hd_kddi_call_unit_prices;
                } else {
                  rows = this.hd_cx_call_unit_prices;
                }
              } else {
                if (invoice_type == consts.INVOICE.KDDI) {
                  rows = this.fc_kddi_call_unit_prices;
                } else {
                  rows = this.fc_cx_call_unit_prices;
                }
              }
//              console.log('get call_unit_price', rows, type, invoice_type);

              return rows;

            },
            getBaseUnitPrices(type) {
              let rows = [];
              if (type == consts.CONTRACT.HD) {
                rows = this.hd_base_unit_prices;
              } else {
                rows = this.fc_base_unit_prices;
              }
              return rows;

            },
            getRows(page = 1) {
                let params = ['page=' + page];

                for(let key in this.searchParams) {
                  params.push(key + '=' + encodeURIComponent(this.searchParams[key]));
                }
                let query = params.join('&');

                this.$Progress.start();
                
                axios.get(THIS_API + '?' + query).then(({ data }) => {
//                    console.log('get rows', this.rows, data);

                    this.rows = data.data;
                });

                this.$Progress.finish();
            },
            resetSearchParams() {
                for(let key in this.searchParams) {
                    this.searchParams[key] = '';
                }
            },

            //  新規作成ボタンクリック時
            onCreate(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal({backdrop: "static",});
            },

            //  編集ボタンクリック時
            onUpdate(row){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal({backdrop: "static",});
                ///  もしも請求単価グループが設定されていなかったときにエラーにならないように初期値を設定する（これはないはずだけど） 
                if (row.cx_call_unit_price.length == 0) {
                  row.cx_call_unit_price = [{ id: '' }];
                }
                if (row.kddi_call_unit_price.length == 0) {
                  row.kddi_call_unit_price = [{ id: '' }];
                }
                if (row.base_unit_price.length == 0) {
                  row.base_unit_price = [{ id: '' }];
                }
                //
                this.form.fill(row);
                console.log('onupdate', row, this.form);
            },
            onDelete(row) {
                let title = row.name;
                let id = row.id;

                Swal.fire({
                    title: title + 'を削除してよろしいですか？',
                    text: "この操作を取り消すことはできません",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: '削除',
                    cancelButtonText: 'キャンセル'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                axios.delete(THIS_API + '/'+id).then((response)=>{
                                    common.doResponse(response);
                                    this.getRows();
                                }).catch((data)=> {
                                  Swal.fire("失敗!", data.message, "warning");
                              });
                         }
                    })
            },

          
            createRow(){
              if (!this.checkValidate(true)) {
                  return;
              }
              this.$Progress.start();

              this.form.post(THIS_API)
              .then((data)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.getRows();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'エラー'
                  });
              })
            },
            updateRow() {
                if (!this.checkValidate(true)) {
                    return;
                }

                this.$Progress.start();
                this.form.put(THIS_API + '/'+this.form.id)
                .then((response) => {
                    // success
                    let result = common.doResponse(response);
                    this.$Progress.finish();

                    if (result) {
                        $('#addNew').modal('hide');
                        this.getRows();
                    }
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            checkValidate(is_show) {
              if (!this.form.name) {
                if (is_show) common.toastError(this.title + '名を入力してください');
                return false;
              }
              if (!this.form.kddi_call_unit_price[0].id) {
                if (is_show) common.toastError('KDDIからCXへの請求を設定してください');
                return false;
              }
              if (!this.form.cx_call_unit_price[0].id) {
                if (is_show) common.toastError('CXからお客様への請求を設定してください');
                return false;
              }
              return true;
            },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.getRows();

            this.loadCallUnitPrices();
            this.loadBaseUnitPrices();

            this.$Progress.finish();
        }
    }
</script>
