<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <div class="card"><!-- 検索-->
              <div class="card-header">
                <h3 class="card-title">検索条件</h3>

                <div class="card-tools">
                  <button type="button" class="btn  btn-sm btn-primary" @click="resetSearchParams()">
                      クリア
                  </button>

                    <button type="button" class="btn  btn-sm btn-primary" @click="getRows()">
                        <i class="fa fa-search"></i>
                        検索
                    </button>
                </div>
              </div>
              <div class="card-body">

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">ID</label>
                        <input v-model="searchParams.id" type="text"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">名前</label>
                        <input v-model="searchParams.name" type="text"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>
                  <div class="row m-t-1" v-if="is_base_price_setting">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">メモ</label>
                        <input v-model="searchParams.comment" type="text"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>

              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card 検索-->
          </div>


          <div class="col-9">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">{{title}}一覧<span v-if="rows && rows.total">({{rows.total + '件中の' + rows.from + '〜'  + rows.to + '件' }})</span></h3>

                <div class="card-tools">
                    <!--  インポート -->
                  <import  @imported="onImported" :model="model" :keys="['id']" :fields="['id', 'name']"  />
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="onCreate()">
                      <i class="fa fa-plus-square"></i>
                      追加
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>名前</th>
                      <th>操作</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="row in rows.data" :key="row.id">

                      <td>{{row.id}}</td>
                      <td>{{row.name}}</td>
                      <td>
                        <a href="#" @click="onUpdate(row)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#" @click="onDelete(row)">
                            <i class="fa fa-trash red"></i>
                        </a>
                        </span>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer clearfix">
                  <pagination :data="rows" :limit="5" @pagination-change-page="getRows"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">新規作成</h5>
                    <h5 class="modal-title" v-show="editmode">編集</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateRow() : createRow()">
                    <div class="modal-body">
                            <div class="form-group">
                                <label>ID(コード)</label>
                                <input v-model="form.id" type="text" name="id"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('id') }">
                                <has-error :form="form" field="id"></has-error>
                            </div>
                            <div class="form-group">
                                <label>名前</label>
                                <input v-model="form.name" type="text" name="name"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                                <has-error :form="form" field="name"></has-error>
                            </div>
                            <span v-if="is_base_price_setting">
                            <div class="form-group">
                                <label>請求単位</label>

                                <div class="form-check" :class="{ 'is-invalid': form.errors.has('unit_type') }">
                                  <label class="form-check-label" >
                                    <input type="radio"  value="1" v-model="form.unit_type">契約・回線&nbsp;</label>
                                  <label class="form-check-label" >
                                    <input type="radio"  value="2" v-model="form.unit_type">追加ch&nbsp;</label>
                                  <label class="form-check-label" >
                                    <input  type="radio"  value="3" v-model="form.unit_type">追加番号&nbsp;</label>
                                  <label class="form-check-label" >
                                    <input  type="radio"  value="4" v-model="form.unit_type">番号(追加番号+1)&nbsp;</label>
                                </div>

                            </div>

                            <div class="form-group">
                                <label>表示名(請求書の項目名)</label>
                                <input v-model="form.display_name" type="text"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('display_name') }">
                                <has-error :form="form" field="display_name"></has-error>
                            </div>
                            <div class="form-group">
                                <label>メモ</label>
                                <input v-model="form.comment" type="text"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('comment') }">
                                <has-error :form="form" field="comment"></has-error>
                            </div>

                            <div class="form-group">
                                <label>料金種別</label>

                                <div class="form-check" :class="{ 'is-invalid': form.errors.has('price_type') }">
                                  <label class="form-check-label" >
                                    <input type="radio"  value="1" v-model="form.price_type">初期費用&nbsp;</label>
                                  <label class="form-check-label" >
                                    <input type="radio"  value="2" v-model="form.price_type">毎月の費用&nbsp;</label>
                                </div>

                            </div>

                            </span>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        <button v-show="editmode" :disabled="!checkValidate(false)" type="submit" class="btn btn-success">更新</button>
                        <button v-show="!editmode" :disabled="!checkValidate(false)" type="submit" class="btn btn-primary">新規作成</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import common from '../../common.js'
import consts from '../../consts.js'
import Import from '../libs/Import.vue';

    export default {
        name: 'settings-price-name',
        props: ['API', 'title', 'model'],
        components: {
            Import,
        },
        data () {
            return {
                editmode: false,
                searchParams: {
                  id: '',
                  name: '',
                  comment: '',
                },

              rows : {},
              form: new Form({
                  id : '',
                  name: '',        // 名称
                  unit_type: 1,                //  単位種別\n1:契約・回線、2:追加ch、3:追加番号、4:番号(追加番号+1)
                  display_name: null, //  表示名\nCXの請求書に掲載する名称。nullのときはnameが表示名となる
                  comment: null,      // コメント\n同じ名前のときの識別に使う
                  price_type: 2,    //  請求単位種別\n1:初期費用。2:毎月の費用
              }),
            }
        },
        computed: {
            consts: function() {
                return consts;
            },
            //  基本料金設定かどうか
            is_base_price_setting: function() {
              return  (this.model == 'App\\Models\\HdPriceName' || this.model == 'App\\Models\\FcPriceName') ? true : false;
            },

        },
        methods: {
            getRows(page = 1) {
                let params = ['page=' + page];

                for(let key in this.searchParams) {
                  params.push(key + '=' + encodeURIComponent(this.searchParams[key]));
                }
                let query = params.join('&');

                this.$Progress.start();
                
                axios.get(this.API + '?' + query).then(({ data }) => {
//                    console.log('get rows', this.rows, data);

                    this.rows = data.data;
                });

                this.$Progress.finish();
            },
            resetSearchParams() {
                for(let key in this.searchParams) {
                    this.searchParams[key] = '';
                }
            },

            //  新規作成ボタンクリック時
            onCreate(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal({backdrop: "static",});
            },

            //  編集ボタンクリック時
            onUpdate(row){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal({backdrop: "static",});
                this.form.fill(row);
                console.log('onupdate', row, this.form);
            },
            onDelete(row) {
                let title = row.name;
                let id = row.id;

                Swal.fire({
                    title: title + 'を削除してよろしいですか？',
                    text: "この操作を取り消すことはできません",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: '削除',
                    cancelButtonText: 'キャンセル'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                axios.delete(this.API + '/'+id).then((response)=>{
                                    common.doResponse(response);
                                    this.getRows();
                                }).catch((data)=> {
                                  Swal.fire("失敗!", data.message, "warning");
                              });
                         }
                    })
            },

          
            createRow(){
              if (!this.checkValidate(true)) {
                  return;
              }
              this.$Progress.start();

              this.form.post(this.API)
              .then((data)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.getRows();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'エラー'
                  });
              })
            },
            updateRow() {
                if (!this.checkValidate(true)) {
                    return;
                }

                this.$Progress.start();
                this.form.put(this.API + '/'+this.form.id)
                .then((response) => {
                    // success
                    let result = common.doResponse(response);
                    this.$Progress.finish();

                    if (result) {
                        $('#addNew').modal('hide');
                        this.getRows();
                    }
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            checkValidate(is_show) {
              if (!this.form.name) {
                if (is_show) common.toastError(this.title + '名を入力してください');
                return false;
              }
              if (!this.form.id) {
                if (is_show) common.toastError('ID(コード)を入力してください');
                return false;
              }
              return true;
            },

            //  インポート完了
            onImported() {
                this.getRows();
            },
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.getRows();

            this.$Progress.finish();
        }
    }
</script>
