export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default },
    { path: '/invoice/hd', component: require('./components/invoice/Hd.vue').default },
    { path: '/invoice/fc', component: require('./components/invoice/Fc.vue').default },
    { path: '/settings/customer', component: require('./components/settings/Customer.vue').default },
    { path: '/settings/customer_tel', component: require('./components/settings/CustomerTel.vue').default },
    { path: '/settings/call_unit_price', component: require('./components/settings/CallUnitPrice.vue').default },
    { path: '/settings/base_unit_price', component: require('./components/settings/BaseUnitPrice.vue').default },
    { path: '/settings/base_unit_price_item/:id', component: require('./components/settings/BaseUnitPriceItem.vue').default, name: 'base_unit_price_item' },

    { path: '/settings/hd_price_name', component: require('./components/settings/HdPriceName.vue').default },
    { path: '/settings/fc_price_name', component: require('./components/settings/FcPriceName.vue').default },
    { path: '/settings/item_name', component: require('./components/settings/ItemName.vue').default },
    { path: '/invoice', component: require('./components/Invoice.vue').default },
];
