<template>
  <section v-if="parent_row && rows" class="content">
    <div class="container-fluid">
    <a  href="#" @click="$router.push('/settings/base_unit_price')">一覧に戻る</a>
        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <div v-if="$gate.isAdmin()" class="row">

          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">検索条件</h3>

                <div class="card-tools">
                  <button type="button" class="btn  btn-sm btn-primary" @click="resetSearchParams()">
                      クリア
                  </button>

                    <button type="button" class="btn  btn-sm btn-primary" @click="getRows()">
                        <i class="fa fa-search"></i>
                        検索
                    </button>
                </div>
              </div>
              <div class="card-body">
                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">コード</label>
                        <input v-model="searchParams.price_name_id" type="text" name="price_name_id"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>

                  <div class="row m-t-1">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="col-form-label meta-label">名称</label>
                        <input v-model="searchParams.name" type="text" name="name"
                              class="form-control" placeholder="">
                      </div>
                    </div>
                  </div>

              </div>
            </div>
          </div>

          <div class="col-9">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">{{parent_row.name}}明細一覧 <span v-if="rows.total">({{rows.total + '件中の' + rows.from + '〜'  + rows.to + '件' }})</span></h3>

                <div class="card-tools">


                  <button  :disabled="!isAnyRowChecked" type="button" class="btn btn-sm btn-primary" @click="deletes">
                      <i class="fa fa-plus-square"></i>
                      削除
                  </button>

                  <button type="button" class="btn btn-sm btn-primary" @click="onCreate()">
                      <i class="fa fa-plus-square"></i>
                      追加
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table v-if="rows" class="table table-hover">
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" v-model="is_all_row_checked" @click="onClickAllRow($event)"/> 
                      </th>
                      <th>コード</th>
                      <th>名称</th>
                      <th>単価</th>
                      <th>単位</th>
                      <th>メモ</th>
                      <th>操作</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="row in rows.data" :key="row.id">
                      <td>
                        <input type="checkbox" v-bind:checked="isRowChecked(row.id)" @click="onClickRow($event, row.id)" />
                      </td>
                      <td>{{row.price_name_id}}</td>
                      <td>{{ getNameFromPriceName(getPriceName(row)) }}</td>
                      <td>{{row.unit_price}}</td>
                      <td>{{ getUnitTypeName(getPriceName(row)) }}</td>
                      <td>{{ getComment(getPriceName(row)) }}</td>
                      <td>
                        <a href="#" @click="onUpdate(row)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="rows" :limit="5" @pagination-change-page="getRows"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>



        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">新規作成</h5>
                    <h5 class="modal-title" v-show="editmode">編集</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateRow() : createRow()">
                    <div class="modal-body">

                            <div class="form-group">
                                <label>コード</label>
                                <input v-model="form.price_name_id" type="text" name="price_name_id" :readonly="editmode" @input="onInputCode"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('price_name_id') }">
                                <has-error :form="form" field="price_name_id"></has-error>
                            </div>
                            <div class="form-group">
                                <label>{{ form.price_name }}</label>
                            </div>
                            <div class="form-group">
                                <label>単価</label>
                                <input v-model="form.unit_price" type="number" step="0.01" name="unit_price"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('unit_price') }">
                                <has-error :form="form" field="unit_price"></has-error>
                            </div>
                            <div class="form-group" v-if="form.unit_type_name">
                                <label>単位</label>
                                <div class="form-check" >
                                  <label class="form-check-label">{{ form.unit_type_name }}</label>

                                </div>
                            </div>
                            <div class="form-group" v-if="form.comment">
                                <label>メモ</label>
                                <div class="form-check" >
                                  <label class="form-check-label">{{ form.comment }}</label>
                                </div>

                            </div>
                    
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">更新</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">新規作成</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>
<style scoped lang="scss">
</style>
<script>
import axios from 'axios';
const THIS_API = '/api/base_unit';
import common from '../../common.js'
import consts from '../../consts.js'

    export default {
        computed: {
          isAnyRowChecked: function () {
            return (this.is_all_row_checked || this.checked_rows.length > 0);
          },
        },

        data () {
            return {
                parent_row: null,
                rows: null,
                editmode: false,
                form: new Form({
                    id : '',
                    base_unit_price_id: null,   //  単価グループID
                    type: null,                  // 契約種別\n1:光ダイレクト、2:フリーコール
                    price_name_id: null,      //  明細コード
                    unit_price: null,           //  単価

                    //  名称表示用
                    price_name: null,
                    comment: null,
                    unit_type_name: null,
//                    hd_price_name : null,
//                    fc_price_name : null,
                }),
                ////  チェック機能関係
                is_all_row_checked: false,
                checked_rows: [],   //  選択状態の行IDの配列（is_all_row_checkedがfalseのとき有効のとき有効）
                unchecked_rows: [],   //  非選択状態の行IDの配列（is_all_row_checkedがtrueのとき有効）
                ////
                searchParams: {
                    type: null,
                    base_unit_price_id: null,
                    price_name_id: '',      //  明細コード
                    name: '',             // 明細名
                },

            }
        },
        watch: {
            $route(to, from) {
                // ルートの変更の検知...  サイドメニューのクリック時
                this.loadParentRow();
            }
        },
   

        methods: {

            loadParentRow() {
              let id = this.$route.params.id;
              this.$Progress.start();
              let url = '/api/base_unit_price/' + id;
              axios.get(url).then(({ data }) => {
                  this.parent_row = data.data;
                  this.resetSearchParams();
                  this.getRows();
              });

              this.$Progress.finish();

            },

            //  検索条件クリア
            resetSearchParams() {
                for(let key in this.searchParams) {
                    this.searchParams[key] = '';
                }
                if (this.parent_row) {
                  this.searchParams.base_unit_price_id = this.parent_row.id;
                  this.searchParams.type = this.parent_row.type;

                }
            },

            getRows(page = 1) {
                let params = ['page=' + page];

                this.searchParams.base_unit_price_id = this.parent_row.id;

                for(let key in this.searchParams) {
                  params.push(key + '=' + encodeURIComponent(this.searchParams[key]));
                }
                let query = params.join('&');

                this.$Progress.start();
                
                axios.get(THIS_API + '?' + query).then(({ data }) => {

                    this.rows = data.data;
                });

                this.$Progress.finish();
            },
            //  新規作成ボタンクリック時
            onCreate(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal({backdrop: "static",});
            },

            //  編集ボタンクリック時
            onUpdate(row){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal({backdrop: "static",});
                this.form.fill(row);
                this.update_form_display(this.getPriceName(row));
            },
            setFixedFormValue() {
              this.form.base_unit_price_id = this.parent_row.id;
              this.form.type = this.parent_row.type;

            },
            createRow(){
              if (!this.checkValidate(true)) {
                  return;
              }
              this.$Progress.start();
              this.setFixedFormValue();

              this.form.post(THIS_API)
              .then((data)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.getRows();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'エラー'
                  });
              })
            },
            updateRow() {
                if (!this.checkValidate(true)) {
                    return;
                }
              this.setFixedFormValue();

                this.$Progress.start();
                this.form.put(THIS_API + '/'+this.form.id)
                .then((response) => {
                    // success
                    let result = common.doResponse(response);
                    this.$Progress.finish();

                    if (result) {
                        $('#addNew').modal('hide');
                        this.getRows();
                    }
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            checkValidate(is_show) {
              if (!this.form.price_name_id) {
                if (is_show) common.toastError('コードを入力してください');
                return false;
              }
              if (!this.form.unit_price) {
                if (is_show) common.toastError('単価を入力してください');
                return false;
              }
              return true;
            },
            getNameFromPriceName(price_name) {
              return common.getNameFromPriceName(price_name);
            },
            update_form_display(price_name) {
              console.log('update form', price_name);
              if (price_name) {
                this.form.comment = this.getComment(price_name);
                this.form.unit_type_name = this.getUnitTypeName(price_name);
                this.form.price_name = this.getNameFromPriceName(price_name);
              } else {
                this.form.comment = "";
                this.form.unit_type_name = "";
                this.form.price_name = "";

              }

            },
            getPriceName(row) {
              return  row.type == consts.CONTRACT.HD ? row.hd_price_name : row.fc_price_name;
            },
            getUnitTypeName(price_name) {
              let unit_type = price_name ? price_name.unit_type : 0;
              let name = null;
              if (unit_type == consts.UNIT_TYPE.CONTRACT) {
                name = '契約/回線数';
              } else if (unit_type == consts.UNIT_TYPE.ADDITIONAL_CHANNEL) {
                name = '追加ch数';
              } else if (unit_type == consts.UNIT_TYPE.ADDITIONAL_TEL) {
                name = '追加番号数';

              } else if (unit_type == consts.UNIT_TYPE.TEL) {
                name = '番号数';
              }
              return name;
            },
            getComment(price_name) {
              return price_name ? price_name.comment : null;

            },

                    ////  チェック機能関係
          resetCheck() {
            this.checked_rows = [];
            this.unchecked_rows = [];
            this.is_all_row_checked = false;
          },
          onClickAllRow(event) {
            this.checked_rows = [];
            this.unchecked_rows = [];

          },
          onClickRow(event, key) {
            let isChecked = event.target.checked;
            let rows;
            if (this.is_all_row_checked) {
              //  すべてチェック状態のとき
              if (isChecked) {
                //  チェックされたら値を削除
                let index = this.unchecked_rows.indexOf(key);
                if (index != -1) {
                  this.unchecked_rows.splice(index, 1);
                }
              } else {
                //   チェック解除で値を追加
                this.unchecked_rows.push(key);
              }
            } else {
              //  すべてチェックされていない状態のとき
              if (isChecked) {
                //   チェックされたら値を追加
                this.checked_rows.push(key);
              } else {
                //  チェック解除で値を削除
                let index = this.checked_rows.indexOf(key);
                if (index != -1) {
                  this.checked_rows.splice(index, 1);
                }
              }
            }
          },
          isRowChecked(key) {
            if (this.is_all_row_checked) {
              //  すべてチェック状態のとき
              let index = this.unchecked_rows.indexOf(key);
              if (index != -1) {
                return false;
              } else {
                return true;
              }
            } else {
              //  すべてチェックされていない状態のとき

              let index = this.checked_rows.indexOf(key);
              if (index != -1) {
                return true;
              } else {
                return false;
              }

            }
          },
          /////

          /// 削除
          //  選択された行を削除
          doDeletes(p) {
            let params = {
              is_all_row_checked: this.is_all_row_checked,
              checked_rows: this.checked_rows,
              unchecked_rows: this.unchecked_rows,
              search_params: this.searchParams,
            };

            this.$Progress.start();
            axios.post(THIS_API + '_deletes', params)
              .then((response) => {
                this.doResponse(response);
              }).catch((data)=> {
                Swal.fire("Failed!", data.message, "warning");
              });

          },
          doResponse(response, successTitle = '削除完了!', successMessage= '削除されました') {
                if(response.data.success){
                  // success
                  Swal.fire(
                    successTitle,
                    successMessage,
                    'success'
                    );
                  this.resetCheck();  

                  //  再読み込み
                  this.getRows();
                  this.$Progress.finish();
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: response.data.message
                  });
                  this.$Progress.finish();
              }
          },

          deletes() {
            common.confirmDelete(this.doDeletes, null, '選択された行を削除します。本当に削除してよろしいですか？');
          },
          ///

          //  コード入力時
          onInputCode() {
            this.form.price_name = null;

            let code = this.form.price_name_id;
//            console.log('コード type', code, this.form.type, this.parent_row.type);

            let api;
            if(this.parent_row.type == consts.CONTRACT.HD) {
              api = '/api/hd_price_name/';
            } else {
              api = '/api/fc_price_name/';
            }
            axios.get(api + code).then(result => {
              if(result.data.success){
                let detail = result.data.data;
                
                this.update_form_display(detail);

//                console.log('コード', code, this.form, result);
              }



            })
            .catch(() => {
            });

          },

        },
        mounted() {
            this.$Progress.start();
            this.loadParentRow();
            this.$Progress.finish();
        },
        created() {

        }
    }
</script>
