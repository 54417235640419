
<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">光ダイレクト請求書</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      作成
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" >請求書作成</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateTag() : createTag()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>光ダイレクト請求明細CSVファイル</label>
                            <input type="file" accept=".csv" v-on:change="fileSelected" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>ファイル名</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>CSVヘッダー行</label>
                            <input v-model="form.csv_header" type="text" name="csv_header"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('csv_header') }">
                            <has-error :form="form" field="csv_header"></has-error>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        <button  type="submit" class="btn btn-primary">作成</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import common from '../../common.js'
import consts from '../../consts.js'
import JSZip from 'jszip'
import saveAs from 'file-saver';

    export default {
        data () {
            return {
                editmode: false,
                tels : {},
                customers: {},
                form: new Form({
                    id : '',
                    name: '',
                    csv_header: '',

                })
            }
        },
        methods: {
            //  明細CSVファイル選択時
            fileSelected(event){
                let fileInfo = event.target.files[0]
                    console.log('file info', fileInfo);
                this.form.name = common.baseName(fileInfo.name);

                common.readTextFile(fileInfo).then(text => {



                    //  フォーマットチェック
                    if (text.indexOf('ご利用電話番号,通話開始日時,通話開始時刻,着信電話番号,地域名,通話時間,内線区分,呼種別,通話料') === 0) {

                        let zip = new JSZip();

                        let rows = common.makeArrayFromCSVText(text, true);     //  ヘッダーをのぞいてCSV配列を生成

                        let customerRows = this.groupByCustomer(rows, 0, this.tels);

                        if (customerRows) {
                            console.log('csv', rows, customerRows);


                            //  顧客ごとの通話時間の合計を計算
                            for (let customer_id in customerRows) {
                                let customerRow = customerRows[customer_id];
                                let customer = this.findCustomer(customer_id);

                                let  zip_folder = zip.folder(customer.name);

                                //  明細ごとにCXからお客様へ請求する金額を計算
                                console.log('calcCxInvoice start', customerRow);
                                let cx_call_unit_price = customer.cx_call_unit_price.length > 0 ? customer.cx_call_unit_price[0] : null;
                                let newRows = this.calcCxInvoice(customerRow, cx_call_unit_price, consts.HD_CSV_INDEX.TIME, consts.HD_CSV_INDEX.PRICE, consts.HD_CSV_INDEX.CALL_TYPE);

/*
                                for (let i in newRows) {
                                    let newRow = newRows[i];
                                    console.log('cx price ' + i , newRow[consts.HD_CSV_INDEX.CX_PRICE]);
                                }
*/
                                let csvText = common.arrayToCSVText(newRows);
                                //  ダウンロード
//                                common.download_txt('test.csv', csvText);
                                zip_folder.file("meisai.csv", csvText);

                                //  秒数合計を計算
                                let sec = common.sumAsTime(customerRow, 5);
                                let min = common.round(sec / 60, 100);  //  分数は少数第２まで



                                //  KDDIからの請求額を計算
                                let kddi_call_unit_price = customer.kddi_call_unit_price.length > 0 ? customer.kddi_call_unit_price[0] : null;
                                if (kddi_call_unit_price) {
                                    let mobile = kddi_call_unit_price.mobile;
                                    console.log('mobile', mobile);
                                    let price = min * kddi_call_unit_price.mobile;
                                    price = Math.round(price);

                                    console.log('通話時間合計', customer, customer_id, sec, min, price);
                                }


                            }
                            
                            zip.generateAsync({type:"blob"})
                                .then(function(content) {
                                    // see FileSaver.js
                                    saveAs(content, "invoice.zip");
                                });



                            let result = rows[1].join(',');


                            this.form.csv_header = result;

                        }



                    } else {
                        alert('CSVの形式が光ダイレクト明細と異なります。');
                    }

                });
            },
            //  明細一覧の明細ごとにCXからお客様への請求額を計算
            //  明細行に計算結果の列を追加して請求額を設定
            calcCxInvoice(rows, call_unit_price, time_index, price_index, call_type_index) {
                if (call_unit_price != null) {

                    for (let index in rows) {
                        let row = rows[index];


                        let sec = common.toSec(row[time_index]);
                        let price = row[price_index];
                        let call_type_text = row[call_type_index];

//                        console.log('calcCxInvoice row', row, sec, price, call_type_text);

                        price = this.calcCxPrice(sec, call_type_text, call_unit_price, price);

                        //  計算結果の列を追加して請求額を設定
                        row.push(price);
//                        console.log('calcCxInvoice row result', row);

                        rows[index] = row;
                    }
                }

                return rows;

            },
            //  CXからお客様への請求額を計算
            calcCxPrice(sec, call_type_text, call_unit_price, price)
            {
                let call_type = this.getCallType(call_type_text);

                let unit_price = 0;
                if (call_type == 1) {
                    //  固定電話
                    unit_price = call_unit_price.fixed_line;
                    
                } else if (call_type == 2) {
                    //  携帯電話
                    unit_price = call_unit_price.mobile;
                } else {
                    //  その他
                    //  約款料金　（そのままでよいか確認）
                }

                if (unit_price != 0) {
                    //  分を切り上げ
                    let min = Math.ceil(sec/60);
                    //  TODO:四捨五入でよいか確認
                    price =  Math.round(min * unit_price);
                    console.log('cx 請求計算 秒 分 価格', call_type_text, sec, min, price);
                }
                return price;

            },
            getCallType(call_type_text) {
                if (call_type_text.indexOf('国内呼') === 0) {
                    return 1;
                } else if (call_type_text.indexOf('携帯呼') === 0) {
                    return 2;
                } else {
                    return 0;
                }


            },


            //  顧客ごとにまとめる
            groupByCustomer(rows, tel_index, tels) {
                let customerRows = {};
                for (let i = 0; i < rows.length; i++) {
                    let row = rows[i];
                    let tel = row[tel_index];
                    let search_tel = common.toSearchTel(tel);
                    if (tels[search_tel]) {
                        //  顧客が決定
                        let customer_id = tels[search_tel];
                        let customerRow = null;
                        if (customerRows[customer_id]) {
                            customerRow = customerRows[customer_id];
                        } else {
                            customerRow = [];
                        }
                        customerRow.push(row);
                        customerRows[customer_id] = customerRow;

                    } else {
                        console.log('err', search_tel, tels);
                        common.toastError('登録されていない電話番号[' + tel + ']の請求が存在しています。この電話番号をお客様電話番号に登録してください');
                        customerRows = null;
                        break;
                    }
                }
                return customerRows;
            },

            loadTels() {

                  this.$Progress.start();
                  
                  axios.get('/api/customer_tel_hd_tels').then( result => {
                      this.tels = result.data.data;
                      this.loadAllCustomer();


//                      console.log('load tels', this.tels);
/*
var toString = Object.prototype.toString;
console.log(toString.call(this.tels));
console.log('result', this.tels['0311111114']);
*/
                  });

                  this.$Progress.finish();
            },
            loadAllCustomer() {
                axios.get('/api/customer_hd').then( result => {
                    this.customers = result.data.data;
                    console.log('customers', this.customers);
                });

            },
            findCustomer(customer_id) {
                return this.customers.find(customer => customer.id == customer_id);

            },


            getResults(page = 1) {

                  this.$Progress.start();
                  
                  axios.get('/api/tag?page=' + page).then(({ data }) => (this.tags = data.data));

                  this.$Progress.finish();
            },
            updateTag(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('/api/tag/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadTags();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(tag){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(tag);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },

            loadTags(){
            },
            
            createTag(){

                this.form.post('/api/tag')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadTags();

                })
                .catch(()=>{

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            }

        },
        mounted() {
        },
        created() {
            this.loadTels();

        }
    }
</script>
