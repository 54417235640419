<style scoped>
.form-group {
    margin-bottom: 10px;
}
</style>
<template>
    <span>
        <button type="button" class="btn btn-sm btn-primary" @click="importModal">
            <i class="fa fa-plus-square"></i>
            インポート
        </button>
        <!-- Modal -->
        <div class="modal fade" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" >インポート<span v-if="is_in_progress && !total">準備中...</span><span v-show="total">&nbsp;&nbsp;({{current}}/{{total}})</span></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">


                        <div class="form-group">
                            <label>インポート元のCSVファイル</label>
                            <input type="file" accept=".csv" v-on:change="fileSelected" class="form-control">

                        </div>
                    </div>

                    <video v-if="is_in_progress" style="top:0; left:0; height:0;" muted autoplay loop>
                        <source src="/images/blank.mp4" type="video/mp4">
                    </video>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        <button :disabled="is_in_progress || !canImport" @click="fileUpload" class="btn btn-success">インポート</button>
                    </div>

                </div>
            </div>
        </div>
    </span>
</template>

<script>
import axios from 'axios';
import common from '../../common';

    export default {
        name: 'import',
        props: ['model', 'keys', 'fields'],

        computed: {
          modalId: function () {
            return 'import_model';
          },
          canImport: function () {
            return this.fileInfo ? true : false;

          },
        },

        data () {
            return {
                fileInfo: null,
                total: null,        //  インポート対象の総数
                current: null,      //  インポート処理完了した件数
                is_in_progress: false,

            }
        },
        methods: {

            importModal(){
                $('#' + this.modalId).modal({backdrop: "static",});
            },
            closeModal() {
                $('#' + this.modalId).modal('hide');
                this.is_in_progress = false;
                this.total = this.current = null;

            },

            //  インポートファイル選択時
            fileSelected(event){


                this.fileInfo = event.target.files[0]

            },
            //  インポート処理
            fileUpload(){
                const formData = new FormData()

                formData.append('file',this.fileInfo)
                formData.append('model', this.model);
                formData.append('keys', this.keys);
                formData.append('fields', this.fields);

                this.is_in_progress = true;

                let api = '/api/import';
                axios.post(api,formData).then(response =>{
                    common.doResponse(response);

                    //  親に通知
                    this.$emit('imported');

                    //  ダイアログを閉じる
                    this.closeModal();


                });

            },





        },
        mounted() {
        },
        created() {
        }
    }
</script>
