<template>
  <price-name :API="'/api/hd_price_name'" :title="'HD統合明細料金コード'" :model="'App\\Models\\HdPriceName'" />
</template>

<script>
import PriceName from './PriceName.vue'

    export default {
        components: {
            PriceName,
        },

        mounted() {
        },
        created() {
        }
    }
</script>
